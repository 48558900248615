import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { VisualComp } from 'src/app/models/responses/visualComponentResponse';
import { VisualService } from 'src/app/services/visual/visual.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { WidgetLibraryService } from 'src/app/services/widget-library/widget-library.service';
import { WidgetLibrary } from 'src/app/models/responses/widgetLibraryResponse';
import { environment } from 'src/environments/environment';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { customDropdownProps, customDropdownSettingProps } from 'src/app/models/globals/customDropdownProps';
import { WidgetConfig } from 'src/app/models/responses/widgetConfig';
import { MatStepper } from '@angular/material/stepper';

declare let monaco: any;

@Component({
  selector: 'app-visual-form',
  templateUrl: './visual-form.component.html',
  styleUrls: ['./visual-form.component.scss']
})
export class VisualFormComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  pageForm: UntypedFormGroup;
  configSelectionForm: FormGroup = new FormGroup({})

  configForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    visibleName: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    value: new FormControl(''),
    description: new FormControl('')
  });
  steppercount: number = 2
  configType: string[] = [
    'Number', 'Text', 'Boolean', 'Color'
  ]
  configFormType: string = 'add'

  selectionList: any[] = []
  addMultipleValue: boolean = false





  displayedColumns: any[] = [
    { title: this.translate.instant("VisualCompFormPage.ConfigName"), width: '20%', selector: 'name', mobile: true },
    { title: this.translate.instant("VisualCompFormPage.VisibleName"), width: '30%', selector: 'visibleName', mobile: true },
    { title: this.translate.instant("VisualCompFormPage.Type"), width: '20%', selector: 'type', mobile: true },
    { title: this.translate.instant("VisualCompFormPage.Value"), width: '25%', selector: 'value', mobile: true },
    { title: 'DataStorePage.Action', width: '5%', selector: 'action', mobile: true }



  ]
  // ['name', 'visibleName', 'type', 'value', 'action'];
  // displayedColumnsName: string[] = [this.translate.instant("VisualCompFormPage.ConfigName"), this.translate.instant("VisualCompFormPage.VisibleName"),
  // this.translate.instant("VisualCompFormPage.Type"), this.translate.instant("VisualCompFormPage.Value"), this.translate.instant("VisualCompPage.Action")];


  // configFormVisible: boolean = this.configList.length <= 0 ? true : false
  configFormVisible: boolean = false


  title: string = "";
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  code: string = 'function x() {\nconsole.log("Hello world!");\n}';
  libraries: WidgetLibrary[] = [];
  selectedLibraries: any[] = [];
  compareFunctionForLibraries = (o1: any, o2: any) => o1 === o2;

  libraryDropdownSettings: customDropdownSettingProps = {
    name: 'Library',
    id: 'Library',
    imageCard: false,
    multipleSelection: true,
    dragActive: false,
    changeSortWithDrop: (e) => { },
    iconMenuActive: false,
    searchActive:true,
    placeholder: this.translate.instant("VisualCompFormPage.SearchLibrary"),
    selectElement: (e) => this.selectLibrary(e),
    progressValue: 0,
    errorValues: { text: '', active: false }


  }
  librarForDropdownMenu: customDropdownProps[] = []

  isCreateForm: boolean = true;
  visual: VisualComp = {
    name: "",
    description: "",
    version: 0,
    icon: "",
    createdby: "",
    updatedby: "",
    creationDate: new Date(),
    updateDate: new Date(),
    code: "",
    domain: "",
    widgetLibraries: [],
    revisions: [],
    access: 0,
    config: []
  }
  libContent = `function Aykutadd(a: number, b: number):number 
  function BussionNavigateDashboard(DashboardId:string,Filter:string):bool
  declare var $test: TEST
`;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VisualFormComponent>,
    private dialog: MatDialog,
    private visualService: VisualService,
    private widgetLibraryService: WidgetLibraryService,
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private translate: TranslateService,
    private functionService: GlobalFunctionService,
    private fb: FormBuilder
  ) { }

  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      allowNonTsExtensions: true
    });

    this.getGlobalFunctionDefinitions();

  }
  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.visual.name, [Validators.required]),
    });
    this.isCreateForm = this.data.type == 'update' ? false : true;
    this.title = this.data.type == 'update' ? this.translate.instant("VisualCompFormPage.TitleUpdate") : this.translate.instant("VisualCompFormPage.TitleCreate");
    if (this.data.visual) {
      this.visual = Object.assign({}, this.data.visual);
      this.selectedLibraries = this.visual.widgetLibraries;
      this.visual.icon = environment.imageUrl + this.visual.icon;
    }
    this.getWidgetLibraries();
  }

  getWidgetLibraries() {
    this.widgetLibraryService.getWidgetLibraries().subscribe(result => {
      this.libraries = result;
      this.librarForDropdownMenu = this.libraries.map(item => {
        return {
          id: item.widgetLibraryId,
          title: item.name,
          subtitle: item.createdby,
          element: item,
          selected: this.visual.widgetLibraries.some(x => x == item.widgetLibraryId)
        }

      })
    });
  }

  upsertVisualComponent() {
    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.visual.widgetLibraries = this.selectedLibraries;
    this.visual.icon = this.visual.icon.split(',')[1];
    this.visualService.upsertVisualComponent(this.visual).subscribe(result => {
      this.spinner.hide();
      this.dialogRef.close(true);
    });
  }

  uploadIconImage(fileInput: any) {
    let file = <File>fileInput.target.files[0];
    if (file.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.visual.icon = reader.result;
    }
  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: 'auto',
      width: '90%',
      data: {
        code: this.visual.code,
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.visual.code = result;
      }
    });
  }

  getGlobalFunctionDefinitions() {
    this.functionService.getGlobalFunctionsDefinitions().subscribe(code => {
      (window as any).monaco.languages.typescript.javascriptDefaults.addExtraLib(code);
    })
  }

  selectLibrary(selected: { selectedList: WidgetLibrary[], selectedElement: WidgetLibrary }) {
    this.selectedLibraries = selected.selectedList.map(item => {
      return item.widgetLibraryId
    })
  }

  addConfigToList() {
    if (this.configFormType == 'add' && this.visual.config.some(x => x.name == this.configForm.value.name)) {
      this.coreService.showErrorMessage('eror')
      return
    }


    var configElement: WidgetConfig = this.configForm.value
    var index = -1
    if (this.selectedConfig)
      index = this.visual.config.findIndex(item => item.name === this.selectedConfig.name);

    if (this.configSelectionForm.value)
      configElement.values = Object.keys(this.configSelectionForm.value).map(key => {
        return this.configSelectionForm.value[key]
      })

    if (this.configFormType !== 'add' && index !== -1) {
      this.visual.config[index] = configElement
    }
    // if (this.visual.config.some(x => x.name == configElement.name)) {
    //   this.visual.config = this.visual.config.filter(x => x.name !== configElement.name)
    // }
    if (this.configFormType == 'add')
      this.visual.config.push(configElement)



    this.coreService.showSuccessMessage(this.translate.instant('Common.Success'))
    this.changeFormVisible()
    // this.configList.push(configElement)


  }

  changeStep(type: string) {
    if (type == 'back') {
      const currentIndex = this.stepper.selectedIndex;
      const prevIndex = currentIndex - 1;
      if (prevIndex >= 0) {
        this.stepper.selectedIndex = prevIndex;
      }
    } else {
      const currentIndex = this.stepper.selectedIndex;
      const prevIndex = currentIndex + 1;
      if (prevIndex <= this.steppercount) {
        this.stepper.selectedIndex = prevIndex;
      }
    }
  }

  changeToggle(event) {
    this.addMultipleValue = event
    if (this.addMultipleValue && this.selectionList.length <= 0) {
      this.addSelectionElement()
    }
  }

  addSelectionElement(item?: string) {

    if (this.selectionList.length > 0) {
      this.selectionList.push({ id: this.selectionList[this.selectionList.length - 1].id + 1, key: item ? item : '' })
    }
    else
      this.selectionList.push({ id: 1, key: item ? item : '' })



    this.configSelectionForm.addControl(this.selectionList[this.selectionList.length - 1].id, this.fb.control(item ? item : ''))


  }

  deleteSelectionElement(item) {
    this.selectionList = this.selectionList.filter(x => x.id !== item.id)
  }

  deleteConfig(item) {
    this.visual.config = this.visual.config.filter(x => x.name !== item.name)
  }
  selectedConfig: WidgetConfig

  actionButtonsClick(data: any) {
    if (data.type == 'edit') {
      this.selectedConfig = data.data
      this.configForm.patchValue({
        name: data.data.name,
        visibleName: data.data.visibleName,
        type: data.data.type,
        value: data.data.value,
        description: data.data.description
      })

      if (data.data.values.length > 0) {

        this.selectionList = []

        data.data.values.map((item, key) => {

          this.addSelectionElement(item)
        })
        this.changeToggle(true)
      }

      this.configFormVisible = true
      this.configFormType = 'update'
    }
    else if (data.type == 'delete')
      this.deleteConfig(data.data)
  }

  changeFormVisible() {


    this.configFormVisible = !this.configFormVisible
    while (Object.keys(this.configSelectionForm.controls).length) {
      const toRemove = Object.keys(this.configSelectionForm.controls)[0];
      this.configSelectionForm.removeControl(toRemove)
    }
    this.addMultipleValue = false
    this.selectionList = []
    this.configFormType = 'add'
    this.configForm.reset()
  }

  changedSort(sortingList: WidgetConfig[]) {
    this.visual.config = sortingList;
  }
}
