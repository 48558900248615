<!-- [ngStyle]="{'height': !isFullscreen ? 'calc(100vh - 171px)' : 'calc(100vh - 40px)' }" -->
<div class="perf-info-button" *ngIf="visibleInfoBox">
    <button (click)="openInfoBox()">
        <mat-icon svgIcon="info"></mat-icon>
    </button>
    
</div>

<div class="dashboard-page" [id]="contentIdKeyword+selectedDashboard?.dashboardId">
    <mat-sidenav-container class="example-container" [autosize]="showMessages">


        <mat-sidenav #sidenav mode="side" opened class="example-sidenav" [fixedInViewport]="false" [fixedTopGap]="0"
            [fixedBottomGap]="0" position="end" opened="false">
            <app-messages [sidenavRef]="sidenav" *ngIf="selectedDashboard"
                [selectedDashboardId]="selectedDashboard.dashboardId">
            </app-messages>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="fullscreen-dashboard-header" *ngIf="isFullscreen">
                <div class="fullscreen-title">
                    <img src="{{baseImageUrl}}{{this.selectedDashboard.icon}}" [routerLink]="['']" />
                    <div class="fullscreen-title-group" [matMenuTriggerFor]="dashboardList">
                        <span>{{selectedDashboard.title}}</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </div>
                </div>
                <div class="action-groups">
                    <button mat-button color="danger" *ngIf="!isAutoPlaying" (click)="autoPlay()">
                        <mat-icon>play_arrow</mat-icon>
                    </button>
                    <button mat-button color="danger" *ngIf="isAutoPlaying" (click)="stopAutoPlay()">
                        <mat-icon>pause</mat-icon>
                    </button>
                    <button mat-button color="danger" (click)="showFullScreen(true)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <app-dashboard-header *ngIf="headerShown" (changeDashboardLayout)="changeDashboardLayout($event)"
                [changeViewList]="changeViewList" (showFullScreenFunc)="showFullScreen($event)"
                (getFilteredDashboardFunc)="getFilteredDashboard($event)" (resetFilterFunc)="resetFilter($event)"
                (applyDropdownFilter)="applyDropdownFilter($event)"
                (clearSelectedFilterGroup)="clearSelectedFilterGroup($event)" [myShares]="myShares"
                (openSideNavFunc)="openSideNav()" [isShownFilterAI]="isShownFilterAI"
                (addActiveDashboardFunc)="addActiveDashboard()" (openDashboardFilterFunc)="openDashboardFilter()"
                (dashboardSelectedFunc)="dashboardSelected($event)" [dashboardList]="dashboards"
                [selectedDashboard]="selectedDashboard" [selectedFilters]="selectedFilters" [redirection]="redirectApp"
                (redirectionFunc)="redirection()" [selectedFilterGroup]="selectedFilterGroup"
                [groupedData]="groupedData" (getShares)="getMyShares()"
                (downloadDashboardFunction)="exportDashboard($event)"></app-dashboard-header>

            <button mat-button class="button hide-button" *ngIf="!button" (click)="toggleDashboardHeaderVisibility()">
                <mat-icon svgIcon="hide"></mat-icon>
            </button>



            <div *ngIf="isShownFilter">
                <app-filter-selection [dimensions]="selectedDashboard.dimensions" [filters]="selectedFilters"
                    [initialFilters]="selectedDashboardInitialFilters" (resetFilter)="resetFilter($event)"
                    [selectedDashboardId]="selectedDashboard.dashboardId" (save)="saveFilter($event)"
                    (close)="closeFilterForm($event)" [isHeaderShown]="true" (openShareForm)="openShareForm($event)">
                </app-filter-selection>
            </div>


            <!-- <div class="filterAI-content" *ngIf="isShownFilterAI">
                <div class="input-container">
                    <input class="chat" type="text"
                        placeholder="Lütfen filtrelemek istediğiniz anahtar kelimeyi girin.."
                        (keyup.enter)="getFilteredDashboard()" [(ngModel)]="userInput">
                    <mat-icon class="send-icon" (click)="getFilteredDashboard()">send</mat-icon>
                    <mat-icon class="close-icon" (click)="isShownFilterAI = false">clear</mat-icon>
                </div>

            </div> -->



            <mat-menu #dashboardList="matMenu">
                <span mat-menu-item *ngFor="let dashboard of dashboards" matTooltip="{{dashboard.title}}"
                    (click)="dashboardSelected(dashboard); sidenav.close()">{{dashboard.title}}</span>
            </mat-menu>

            <mat-menu #gridType="matMenu">
                <span mat-menu-item (click)="changeDashboardLayout('Fit')">{{ 'MainPage.FitToScreen' | translate
                    }}</span>
                <span mat-menu-item (click)="changeDashboardLayout('ScrollVertical')">{{ 'MainPage.ScrollVertical' |
                    translate }}</span>
                <span mat-menu-item (click)="showFullScreen(false)">{{ 'MainPage.FullScreen' | translate }}</span>
            </mat-menu>

            <div #dashboardPage class="grid-container"
                [ngStyle]="{'height': !isFullscreen ? 'calc(100vh - 95px)' : 'calc(100vh - 40px)' }"
                [ngStyle]="{'height':fullscreen ? 'calc(100vh - 70px)':'calc(100vh - 95px)'}"
                [ngStyle]="{'height':selectedFilterGroup.length<=0?'calc(100vh - 50px)':'calc(100vh - 95px)'}">

                <gridster id="capture" [options]="options" #gridcontainer>
                    <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
                        <app-widget-preview [showZoom]="true" [showShare]="true" [widget]="item"
                            (expandEvent)="expandWidget($event)" (shareEvent)="openShareForm($event)"
                            [selectedDashboard]="selectedDashboard" [selectedFilters]="selectedFilters">
                        </app-widget-preview>
                    </gridster-item>
                </gridster>
            </div>


        </mat-sidenav-content>
    </mat-sidenav-container>
</div>


<div class="footer">

    <button *ngIf="!selectedDashboard.hideAdvancedQueryPanel" mat-button color="primary"
        (click)="openDashboardFilter()">
        <mat-icon svgIcon="filter"></mat-icon>

    </button>
    <button mat-button color="primary" (click)="openDashboardFilterAI()">
        <mat-icon>filter_list</mat-icon>
    </button>

    <button *ngIf="permissions.includes('DashboardShare') && authorizedDashboards.length != 0" mat-button
        color="primary" (click)=" openViewsModal()"> <mat-icon svgIcon="eye-svgrepo-com"></mat-icon>
    </button>


    <button mat-button color="primary" *ngIf=" permissions.includes('DashboardMessage')" (click)="sidenav.toggle()">
        <mat-icon svgIcon='message'></mat-icon>
    </button>

    <!-- <button mat-button color="primary" *ngIf="button_" (click)="toggleDashboardHeaderVisibilityMobile()">
        <mat-icon svgIcon="hide"></mat-icon>
    </button> -->


</div>
<!-- 
<ng-container *ngIf="showDialog">
    <app-views *ngFor="let share of myShares" [item]="share" [class]="'inside-comp'"
    (saveFilter)="saveFilterFromShares($event)"></app-views> 
    <app-views [(myShares)]="myShares" [class]="'inside-comp'" (saveFilter)="saveFilterFromShares($event)"
        (getShares)="getMyShares()" (closeView)="closeViewsScreen()"></app-views>
</ng-container> -->

<div id="download">
    <img #canvas>
    <a #downloadLink></a>
</div>