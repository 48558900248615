import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { dateEditor } from 'src/app/core/utils';
import { Dimension } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';

@Component({
  selector: 'app-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss']
})
export class FilterBadgeComponent implements OnInit, OnChanges {

  @Input()
  set selectedFilter(value: { content: BussionFilterDefinition[], dimension: Dimension }) {
    // selectedFilter değeri her değiştiğinde buradaki kod çalışır
    // value, yeni gelen değeri temsil eder

    // Burada yeni değeri işleyebilirsiniz.
    // Örneğin, yeni değeri bir değişkene atayabilirsiniz.
    this.concatfilter = []
    this._selectedFilter = value;

    console.log('this._selectedFilter.dimension', this._selectedFilter.dimension)
    if (this._selectedFilter.dimension.isOrdered) {
      this.sortType = 'none'
    } else {
      if (this._selectedFilter.dimension.type.includes('DateTime')) {
        this.sortType = 'desc'
      } else {
        this.sortType = 'asc'
      }
    }

    // this._selectedFilter.content = this._selectedFilter.content.filter(x => x.fieldValue !== '' && x.operator.length <= 2 && this._selectedFilter.dimension.value.includes(x.fieldValue))
    this._selectedFilter.content = this._selectedFilter.content.filter(x => (x.fieldValue !== '' && x.operator.length <= 2) || (x.fieldValue == '' && x.operator.length > 3) || (x.fieldValue !== '' && x.operator.length > 3))

    this.selectboxValue = this.createSelectBoxValue(this._selectedFilter.dimension.value)


    this._selectedFilter.content.map(con => {
      if (con.operator.length > 3 && !this.selectboxValue.some(x => x.operator == con.operator)) {
        this.selectboxValue.push({ name: con.operator, operator: '' })
      }
    })

    this.changeSort()


    // veya başka bir işlem yapabilirsiniz.
  }

  rangeStart: Date
  rangeEnd: Date
  numberInput: number = null

  @Input() selectedFilters: BussionFilterDefinition[] = []

  @Output() applyDropdownFilter: EventEmitter<any> = new EventEmitter<any>()
  @Output() clearSelectedFilterGroup: EventEmitter<any> = new EventEmitter<any>()

  filterserachText: string = ''
  sortType: string = 'asc'

  // Özel bir değişken kullanarak değeri saklayabilirsiniz
  _selectedFilter: { content: BussionFilterDefinition[], dimension: Dimension };
  selectboxValue: { name: string, operator: string }[] = []
  concatfilter: BussionFilterDefinition[] = []
  searchTextInput: string = ''
  constructor(private scriptLoader: ScriptLoaderService) { }


  ngOnInit(): void {
    this.concatfilter = this.selectedFilters
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  filterselect(filter: BussionFilterDefinition) {

  }

  createSelectBoxValue(dimensionsValue: string[]) {
    if (this._selectedFilter.dimension.type.includes('String')) {
      return dimensionsValue.map(item => {
        var currentFilter = this._selectedFilter.content.find(x => x.fieldValue == item)
        if (currentFilter && currentFilter.operator !== '=')
          return { name: item, operator: currentFilter.operator }
        else
          return { name: item, operator: '' }
      })
    } else {
      return this._selectedFilter.content.map(item => {
        if (item.operator !== '=')
          return { name: item.fieldValue, operator: item.operator }
        else
          return { name: item.fieldValue, operator: '' }
      })
    }
  }

  getClasname(filter: string) {
    if (this._selectedFilter.content.some(x => x.fieldValue == filter || (x.operator.length > 3 && x.operator == filter)))
      return 'selected-element'
    else
      return ''
  }

  onMenuClosed(event: Event) {
    // event.preventDefault(); // Menünün kapanmasını engeller.



    var currentFilter = this.selectedFilters.filter(x => x.fieldName !== this._selectedFilter.dimension.name).concat(this._selectedFilter.content)

    var dif = currentFilter.filter(({ fieldValue: id1 }) => !this.selectedFilters.some(({ fieldValue: id2 }) => id2 === id1))
    var dif2 = this.selectedFilters.filter(({ fieldValue: id1 }) => !currentFilter.some(({ fieldValue: id2 }) => id2 === id1))



    if (dif.length > 0 || dif2.length > 0) {
      // this.selectedFilters = this.selectedFilters.concat(this.concatfilter)
      this.applyDropdownFilter.emit(currentFilter)
    }

    // this.scriptLoader.setFilter(this.concatfilter, false)

  }

  clearSelectedFilter(filter: { content: BussionFilterDefinition[], dimension: Dimension }) {
    var currentFilter = this.selectedFilters
    var newFilter = currentFilter.filter(x => x.fieldName !== filter.dimension.name)

    this.concatfilter = newFilter
    this.clearSelectedFilterGroup.emit(this.concatfilter)
    // this.scriptLoader.selectedFilters.next(newFilter)
    // this.scriptLoader.filterChanges.next(newFilter)
  }

  selectFilter(filter: string, type: string) {
    this.searchTextInput = ''

    if (type !== 'selected-element') {
      let currentFilter: BussionFilterDefinition =
      {
        fieldName: this._selectedFilter.dimension.name,
        fieldValue: filter,
        operator: '='
      }
      this._selectedFilter.content.push(currentFilter)
      this.concatfilter = this.selectedFilters.filter(x => x.fieldName !== this._selectedFilter.dimension.name).concat(this._selectedFilter.content)
    } else {
      this._selectedFilter.content = this._selectedFilter.content.filter(x => x.fieldValue !== filter)
      let currentFilter: BussionFilterDefinition[] = this._selectedFilter.content.filter(x => x.fieldValue !== filter)
      this.concatfilter = this.selectedFilters.filter(x => x.fieldName !== this._selectedFilter.dimension.name).concat(currentFilter)


    }

    this.searchText('')
    // this.changeSort()

  }



  searchText(event: any) {

    if (event == '') {
      this.selectboxValue = this.createSelectBoxValue(this._selectedFilter.dimension.value)
    } else {
      var filtereddim = this._selectedFilter.dimension.value.filter(x => x.toLocaleLowerCase('tr').includes(event.target.value.toLocaleLowerCase('tr')))
      this.selectboxValue = this.createSelectBoxValue(filtereddim)
    }
    this.changeSort()
  }

  changeSort() {
    var selectedValues = []
    this._selectedFilter.content.map(item => {
      if (this.selectboxValue.some(x => (item.operator.length <= 2 && x.name == item.fieldValue) || (item.operator.length > 3 && x.name == item.operator)))
        selectedValues.push(this.selectboxValue.find(x => (item.operator.length <= 2 && x.name == item.fieldValue) || (item.operator.length > 3 && x.name == item.operator)))

      this.selectboxValue = this.selectboxValue.filter(x => (item.operator.length <= 2 && x.name !== item.fieldValue) || (item.operator.length > 3 && x.name !== item.operator))
      if (this.sortType !== 'none') {
        this.selectboxValue = this.sortType == 'asc' ? this.selectboxValue.sort((a, b) => a.name.localeCompare(b.name)) : this.selectboxValue.sort((a, b) => b.name.localeCompare(a.name))
      }
    })
    this.selectboxValue = selectedValues.concat(this.selectboxValue)
  }

  dateSelected() {
    var startTimeISOString = this.rangeStart?.toISOString();
    var endTimeISOString = this.rangeEnd?.toISOString();
    var time
    var rangeStartFilter
    var rangeEndFilter


    let currentFilter: BussionFilterDefinition =
    {
      fieldName: this._selectedFilter.dimension.name,
      fieldValue: '',
      operator: ''
    }

    if (startTimeISOString && endTimeISOString) {
    
      rangeStartFilter = dateEditor(startTimeISOString,'start')

      rangeEndFilter = dateEditor(endTimeISOString,'end')

      currentFilter.fieldValue = rangeStartFilter + '/' + rangeEndFilter;
      currentFilter.operator = 'between'

    } else {
   
      currentFilter.fieldValue = dateEditor(startTimeISOString,'start')
      currentFilter.operator = '='
    }

    if (currentFilter.operator == 'between') {
      const [startDate, endDate] = currentFilter.fieldValue.split('/');
      const startFilter = {
        fieldName: currentFilter.fieldName,
        fieldValue: startDate,
        operator: '>'
      };
      const endFilter = {
        fieldName: currentFilter.fieldName,
        fieldValue: endDate,
        operator: '<'
      };

      this._selectedFilter.content.push(startFilter)
      this._selectedFilter.content.push(endFilter)
    } else {
      this._selectedFilter.content.push(currentFilter)

    }

    this.selectboxValue = this.createSelectBoxValue(this._selectedFilter.dimension.value)

    this.concatfilter = this.selectedFilters.filter(x => x.fieldName !== this._selectedFilter.dimension.name).concat(this._selectedFilter.content)

    this.rangeStart = null
    this.rangeEnd = null
  }

  numberselected(event) {
    let currentFilter: BussionFilterDefinition =
    {
      fieldName: this._selectedFilter.dimension.name,
      fieldValue: event.target.value,
      operator: '='
    }

    this._selectedFilter.content.push(currentFilter)
    this.selectboxValue = this.createSelectBoxValue(this._selectedFilter.dimension.value)

    this.concatfilter = this.selectedFilters.filter(x => x.fieldName !== this._selectedFilter.dimension.name).concat(this._selectedFilter.content)
    this.numberInput = null
  }




}
