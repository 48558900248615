import { NO_ERRORS_SCHEMA, AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewContainerRef, ɵcompileComponent } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";
import { cerateCssFile, idKeyword } from "src/app/core/utils";
import { App } from "src/app/models/responses/appsResponse";
import { Dashboard, DashboardResponse } from "src/app/models/responses/dashboardResponse";
import { User } from "src/app/models/responses/usersResponse";
import { BussionMainService } from "src/app/services/bussion-main/bussion-main.service";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { DeployAppService } from "src/app/services/deploy-app/deploy-app.service";
import { ScriptLoaderService } from "src/app/services/script-loader/script-loader.service";
import { UserService } from "src/app/services/user/user.service";
import { environment } from "src/environments/environment";
import { BrowserModule, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { DynamicHomeComponent } from "src/app/components/dynamic-home/dynamic-home.component";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { MaterialModule } from "src/app/material.module";
import { MatIconModule } from "@angular/material/icon";

@Component({

    selector: 'app-home',
    template: '',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    page: number = 1
    // dynamicContent: string;

    classNameAll: string = 'filter-active'
    classNameDash: string = ''
    classNameApp: string = ''
    dframe: string = 'none'
    dlist: string = 'flex'
    users: User[]
    selectedDashboard: any;
    constructor(private deployAppService: DeployAppService,
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService,
        private userService: UserService,
        private router: Router,
        private componentFactoryResolver: ComponentFactoryResolver,
        private bussionMainService: BussionMainService,
        private scriptLoader: ScriptLoaderService, private cookieService: CookieService,
        private sanitizer: DomSanitizer,
        private viewContainerRef: ViewContainerRef
    ) { }
    baseUrl = environment.imageUrl;
    src: string
    dynamicContent: string;
    ngOnInit(): void {
        //   AOS.init();
        this.getApps()
        this.getDashboards()
        // this.getDashboard()
        // if(this.dashboard?.css)


    }
    getData() {
        debugger;
        Msg.DataHandler = this.selectedPage;
        // Msg.BaseUrl = this.baseUrl;

        //return s.selectedPagthie;
    }
    getBussionMain() {
        this.bussionMainService.getBussionMain('html').subscribe((res: any) => {
            this.dynamicContent = res
            this.createMainPage();
            // this.dynamicContent = this.sanitizer.bypassSecurityTrustHtml(res) as SafeHtml
        })
    }

    openSelfServisMain() {
        this.router.navigate(['selfservice-main'])
    }



    createMainPage() {

        ɵcompileComponent(DynamicHomeComponent, {
            //jit:true,
            template: this.dynamicContent,
            standalone: true,
            imports: [NgFor, NgIf, NgClass, TranslateModule, MaterialModule, CommonModule, MatIconModule],
            providers: [],
            schemas: [NO_ERRORS_SCHEMA]
        });

        this.viewContainerRef.clear();
        this.viewContainerRef.createComponent(DynamicHomeComponent);
    }

    selectedPage: any[] = []
    Applications: any[]
    Dashboards: any[]
    dashboard: any
    openerMethod = [
        { 1: 'internal' },
        { 2: 'newtab' },
        { 3: 'pupop' }

    ]


    private getDashboard() {
        this.spinner.show()
        let id = this.dashboardService.getLastDashboardId()
        // if(this.dashboard?.css)
        // cerateCssFile(this.dashboard.css, this.selectedDashboard.dashboardId);
        this.dashboardService.getDashboard(id).subscribe(result => {
            this.dashboard = result;
            this.spinner.hide()

        });
    }


    getLastApp() {
        let lastusedapp = JSON.parse(localStorage.getItem('app'))
        return lastusedapp;
    }

    getApps() {
        this.spinner.show()
        this.deployAppService.GetAuthorizedApp().subscribe(apps => {
            this.Applications = apps
            Msg.Application = this.Applications
            this.spinner.hide()

        })
    }
    selectedList: any[] = []
  contentIdKeyword=idKeyword.dashboard


    getDashboards() {
        this.spinner.show()
        this.Applications = this.dashboardService.cachedData.getValue().authorizedApps
        this.Dashboards = this.dashboardService.cachedData.getValue().authorizedDashboard.filter(x => x.isMainDashboard === 1);

        if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {

            this.Dashboards = this.dashboardService.cachedData.getValue().authorizedDashboard.filter(x => x.isMainDashboard === 1);


            this.selectedList = this.Dashboards.concat(this.Applications)
            this.selectedPage = this.Dashboards.concat(this.Applications)
            this.pagination(this.page)
            this.spinner.hide()
            debugger;
            Msg.DataHandler = this.selectedPage;
            Msg.SelectList = this.selectedList;
            Msg.Application = this.Applications;
            Msg.BaseUrl = this.baseUrl;
            Msg.DashboardService = this.dashboardService;
            Msg.ScriptLoader = this.scriptLoader;
            Msg.Router = this.router;
            Msg.CssFolder = cerateCssFile(this.dashboard?.css, this.selectedDashboard?.dashboardId,this.contentIdKeyword);
            this.getBussionMain();
        } else
            this.dashboardService.getAuthorizedDashboards().subscribe(result => {
                this.Dashboards = result.filter(x => x.isMainDashboard === 1);
                this.selectedList = this.Dashboards.concat(this.Applications)
                this.selectedPage = this.Dashboards.concat(this.Applications)
                this.pagination(this.page)
                this.spinner.hide()
                debugger;
                Msg.DataHandler = this.selectedPage;


                Msg.Dashboards = this.Dashboards
                Msg.SelectList = this.selectedList;
                Msg.Application = this.Applications;
                Msg.BaseUrl = this.baseUrl;
                Msg.DashboardService = this.dashboardService;
                Msg.ScriptLoader = this.scriptLoader;
                Msg.Router = this.router;
                Msg.CssFolder = cerateCssFile(this.dashboard?.css, this.selectedDashboard?.dashboardId,this.contentIdKeyword);
                this.getBussionMain();

            });
    }
    select(type: string) {
        if (type === 'All') {
            this.selectedList = this.Dashboards.concat(this.Applications)
            this.selectedPage = this.Dashboards.concat(this.Applications)
            this.pagination(this.page = 1)
            this.classNameAll = 'filter-active'
            this.classNameDash = ''
            this.classNameApp = ''
        } else if (type === 'Dashboard') {
            this.selectedList = this.Dashboards
            this.selectedPage = this.Dashboards
            this.pagination(this.page = 1)
            this.classNameAll = ''
            this.classNameApp = ''
            this.classNameDash = 'filter-active'
        } else if (type === 'App') {
            this.selectedList = this.Applications
            this.selectedPage = this.Applications
            this.pagination(this.page = 1)
            this.classNameDash = ''
            this.classNameAll = ''
            this.classNameApp = 'filter-active'
        }
        Msg.DataHandler = this.selectedPage;
        Msg.SelectList = this.selectedList;
        Msg.Application = this.Applications
    }

    navigation(item: any) {
        if (item.applicationLink) {
            localStorage.setItem('app', JSON.stringify(item))
            if (item.openerMethod == 1) {//internal
                window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
            } else if (item.openerMethod == 2) {//newtab
                window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
            } else if (item.openerMethod == 3) {//popup
                let options = {
                    type: 'leftWindow', //newWindow,centerWindow,rightWindow,leftWindow
                    windowWidth: '100%',
                    windowHeight: '100%',
                    isapp: 'true',
                }
                this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
            }
        } else {
            // this.cookieService.setItem('lastDashboards',)
            this.dashboardService.setLastDashboardId(item.dashboardId)
            this.router.navigate(["/main"], { state: item });
        }
    }



    pagination(pageNumber: number) {
        // if (pageNumber * 6 <= this.selectedList.length) {
        this.selectedPage = this.selectedList.slice((pageNumber - 1) * 8, ((pageNumber - 1) * 8) + 8)

        // }

    }
    getUserImage(ownerId: string) {
        let avatar = this.users.find(x => x.userId == ownerId)?.avatar
        return this.baseUrl + avatar
    }
}



export class Msg {
    public static BaseUrl: string;
    public static DataHandler;
    public static DashboardService;
    public static ScriptLoader;
    public static Router;
    public static CssFolder;
    public static Pagination;
    public static SelectList;
    public static Application;
    public static Dashboards;
}