<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">

    <app-title title="{{ 'GroupFormPage.AboutGroup' | translate }}"></app-title>
    <form [formGroup]="pageForm">
        <mat-form-field>
            <mat-label>{{ 'GroupFormPage.Name' | translate }}</mat-label>
            <input matInput [(ngModel)]="group.groupName" formControlName="groupName" required>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'GroupFormPage.Description' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="group.description" [ngModelOptions]="{standalone: true}"></textarea>
        </mat-form-field>
        <br>

        <div style="display: flex;align-items: center;">
            <mat-form-field>
                <mat-label>{{ 'UserFormPage.MainPage' | translate }}</mat-label>
                <mat-select [(ngModel)]="mainpage" [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="">{{'Common.Select'|translate}}</mat-option>
                    <mat-option *ngFor="let item of appList" [value]="item.applicationId">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button (click)="mainpage=''" mat-stroked-button class="custom-primary-stroked-button">
                {{ 'Common.Reset' | translate }}
            </button>
        </div>
        <br>

        <div style="display: flex;flex-direction:row">
            <mat-checkbox color="primary" [(ngModel)]="group.isSelfRegistration" class="example-margin"
                [checked]="group.isSelfRegistration" [ngModelOptions]="{standalone: true}">
                {{ 'GroupFormPage.SelfRegistiration' | translate }}
            </mat-checkbox>
        </div>
        <br>


        <app-title title="{{ 'GroupFormPage.MembersOfGroup' | translate }}"></app-title>

        <mat-form-field>
            <input matInput type="text" placeholder="Search" (keyup)="applyFilterMembersOfGroup($event.target.value)">
        </mat-form-field>

        <mat-selection-list (selectionChange)="changeselectedlist($event)" [compareWith]="compareFunctionForUser">
            <mat-list-option [selected]="user.selected" [value]="user.userId"
                *ngFor="let user of paginatedMembersGroup">
                {{user.firstName}} {{user.lastName}}
            </mat-list-option>
        </mat-selection-list>

        <div class="pagination">
            <button class="pagination__button" [disabled]="currentPage === 1" (click)="previousPage()">«</button>
            <span class="pagination__page-number">{{ currentPage }}</span>
            <button class="pagination__button" [disabled]="currentPage === totalPages" (click)="nextPage()">»</button>
        </div>
        <br>

        <app-title title="{{ 'GroupFormPage.DashboardOfGroup' | translate }}"></app-title>
        <mat-form-field>
            <input matInput type="text" placeholder="Search" (keyup)="applyFilterDashboardOfGroup($event.target.value)">
        </mat-form-field>
        <div style="margin-bottom: 10px;display: flex;justify-content: end;">
            <button (click)="sortActive()" mat-stroked-button color="primary">{{sorting?('FilterComponent.saveSort' |
                translate):('FilterComponent.changeSort' | translate)}}
                <mat-icon [svgIcon]="'sort'"
                    style="transform: scale(0.5);width: 40px !important;height: 40px !important"></mat-icon>
            </button>
        </div>
        <mat-selection-list (selectionChange)="changeselectedDahboardlist($event)"
            [compareWith]="compareFunctionForDashboard" cdkDropList (cdkDropListDropped)="drop($event)"
            [cdkDropListDisabled]="!sorting">
            <mat-list-option [selected]="dashboard.selected" *ngFor="let dashboard of paginatedDashboarGroup"
                [value]="dashboard.dashboardId" cdkDrag>
                <div style="display: flex;align-items: center;">
                    <mat-icon *ngIf="sorting" cdkDragHandle [svgIcon]="'draghand'"
                        style="transform: scale(0.6);width: 30px;height: 30px;">
                    </mat-icon>
                    {{dashboard.title}}
                </div>

            </mat-list-option>
        </mat-selection-list>
        <div class="pagination">
            <button class="pagination__button" [disabled]="currentPage1 === 1"
                (click)="previousPageDashboard()">«</button>
            <span class="pagination__page-number">{{ currentPage1 }}</span>
            <button class="pagination__button" [disabled]="currentPage1 === totalPages1"
                (click)="nextPageDashboard()">»</button>
        </div>
        <br>
        <app-title title="{{ 'GroupFormPage.DatasourceOfGroup' | translate }}"></app-title>
        <mat-form-field>
            <input matInput type="text" placeholder="Search"
                (keyup)="applyFilterDatasourceOfGroup($event.target.value)">
        </mat-form-field>
        <mat-selection-list (selectionChange)="changeselectedDatalist($event)"
            [compareWith]="compareFunctionForDataStores">
            <mat-list-option [selected]="data.selected" *ngFor="let data of paginatedDataSource"
                [value]="data.dataStoreId">
                {{data.name}}
            </mat-list-option>
        </mat-selection-list>
        <div class="pagination">
            <button class="pagination__button" [disabled]="currentPage2 === 1"
                (click)="previousPageDataSource()">«</button>
            <span class="pagination__page-number">{{ currentPage2 }}</span>
            <button class="pagination__button" [disabled]="currentPage2 === totalPages2"
                (click)="nextPageDataSource()">»</button>
        </div>
        <br>

        <app-title title="{{ 'DashboardFormPage.FilterForDashboard' | translate }}"
            buttonTitle="+ {{ 'Common.AddNewFilter' | translate }}" (btnEvent)="isShownFilterForm = !isShownFilterForm">
        </app-title>


        <app-filter-form *ngIf="isShownFilterForm" (add)="addFilter($event)">
        </app-filter-form>

        <div class="filter-list-content" *ngIf="group.filter.length > 0">
            <app-filter-list [data]="group.filter" (delete)="deleteFilter($event)"></app-filter-list>
        </div>
        <br>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="!isCreateForm" (click)="upsertGroup()">{{
        'Common.Update' | translate }}</button>
    <button mat-button class="b-btn b-success" cdkFocusInitial *ngIf="isCreateForm" (click)="upsertGroup()">{{
        'Common.Create' | translate }}</button>
</mat-dialog-actions>