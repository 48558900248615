<div class="custom-header-container">
    <div class="bd-title-container">
        <div class="bd-text">
            <span *ngFor="let item of _elements;index as i" (click)="routetoPage(item)">
                {{item.name |translate}}
                <span *ngIf="i<_elements.length-1">/</span>
            </span>

        </div>
        <div class="page-title">{{title |translate}}</div>
    </div>
    <div class="manage-area" [ngClass]="{'manage-area-no-search': !searchareaActive}">
        <div class="search-area" *ngIf="searchareaActive">
            <mat-icon svgIcon="search"></mat-icon>

            <input class="search-input">
        </div>
        <div class="avatar-area" [ngClass]="{'avatar-area-no-search': !searchareaActive}">
            <!-- <mat-icon svgIcon="moon"></mat-icon> -->
            <mat-icon [matTooltip]="'HeaderComponent.Logout'|translate" (click)="logout()" svgIcon="logout"></mat-icon>
            <mat-icon *ngIf="roles.includes('Admin')"  [matTooltip]="'HeaderComponent.Manage' | translate" (click)="goManage()"
                svgIcon="setting-custom"></mat-icon>

            <img class="avatar" [matTooltip]="'HeaderComponent.UpdateInfo' | translate" (click)="showUserInfo()" src="{{avatar}}" />
        </div>
    </div>
</div>

<mat-menu #userMenu="matMenu">
    <span mat-menu-item (click)="showUserInfo()">{{ 'HeaderComponent.UpdateInfo' | translate }}</span>
    <!-- <span mat-menu-item (click)="goManage()">{{ 'HeaderComponent.Manage' | translate }}</span>
    <span mat-menu-item (click)="logout()">{{ 'HeaderComponent.Logout' | translate }}</span> -->
</mat-menu>