import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { WidgetFormComponent } from 'src/app/pages/manage/dashboard/dashboard-designer/widget-form/widget-form.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { SelfServiceDashboardFormComponent } from '../self-service-dashboard-form/self-service-dashboard-form.component';
import { ShareFormComponent } from '../modals/share-form/share-form.component';
import { GridsterWidget, Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { deleteCssFile } from 'src/app/core/utils';
import { CoreService } from 'src/app/services/core/core.service';
import { ExpandedWidgetComponent } from '../modals/expanded-widget/expanded-widget.component';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AvailableWidgetSelectionComponent } from '../available-widget-selection/available-widget-selection.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-self-service-designer',
  templateUrl: './self-service-designer.component.html',
  styleUrls: ['./self-service-designer.component.scss']
})
export class SelfServiceDesignerComponent implements OnInit, OnDestroy {
  @Input() data: {
    type: string,
    dashboard: Dashboard
  }
  constructor(
    private router: ActivatedRoute,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private translate: TranslateService,
    private scriptLoader: ScriptLoaderService,
    private coreService: CoreService,
    private deviceService: DeviceDetectorService,
    private globalFunctionService: GlobalFunctionService
  ) { }

  dashboard: Dashboard = {
    dashboardId: "",
    title: "",
    icon: "",
    auditEnabled: 0,
    order: 1,
    dashboardStatus: 0,
    description: "",
    layoutConfig: "",
    domain: "",
    access: 0,
    owner: "",
    creationDate: "",
    updatedBy: "",
    updateDate: "",
    colorPalette: "",
    contentLanguage: '',
    filter: [],
    widgets: [],//Widget,
    dimensions: [], //Dimension,
    messages: [], //BussionMessage
    autoRefresh: 0,
    refreshInterval: 0,
    isMainDashboard: 0,
    hideBorders: "true",
    gridSize: 0,
    tags: "",
    css: "",
    ownerId: '',
    users: '',
    hideAdvancedQueryPanel: 0,
    layout: '',
    colors: []

  };
  widget: Widget = {
    visualComponentId: "",
    title: "",
    subTitle: "",
    footNote: "",
    access: 1,
    dateGroup: 0,
    customizeCode: 0,
    type: "",
    dataStoreId: "",
    owner: null,
    includeZeroValues: 1,
    domain: "",
    limitData: "500",
    code: "",
    sharedData: null,
    sort: {
      sortField: "",
      sortOrder: "",
      sortType: ""
    },
    colors: {
      type: 0,
      colors: []
    },
    dimensions: [],
    measures: [],
    filter: [],
    widgetParameters: [],
    dimensionsData: [],
    layout: {
      cols: 1,
      rows: 1,
      x: 0,
      y: 0,
      designerContainer: null
    },
    freezeFilter: 0,
    drillDownDashboard: '',
    drillDownLink: '',
    drillDown: {
      dashboard: '',
      dashboardFilter: 0,
      height: 0,
      width: 0,
      link: '',
      openerMethod: '',
      widgetFilter: 0,
      shareKey: ''

    },
    config: [],
    params: {},
    calculatedFields: [],
    isFilter: 0,
    offSet: -1,
    widgetQuery: '',
    ignoreFilterList: [],
    disableInMemoryCache: 0,
    widgetJoin:[]
  };

  firstForm: UntypedFormGroup;
  secondForm: UntypedFormGroup;
  thirdForm: UntypedFormGroup;
  loadingWidgets: boolean = false
  options: GridsterConfig;
  dashboardWidgets: Array<GridsterItem> = [];
  isShownFilter: boolean = false;
  private widgetList = [];
  dashboardId: string = "";
  pageForm: UntypedFormGroup;
  selectedDashboardId: string
  domain: string = null;
  visibletoggle: boolean = false

  routeList = [{ path: "/selfservice", name: this.translate.instant("SelfServicePage.SelfServiceHome") }, { path: "/selfservice/dashboard-list", name: this.translate.instant("SelfServicePage.DashboardList") }, { path: "/selfservice", name: this.translate.instant("SelfServicePage.DashboardDesigner") }]
  ngOnInit(): void {
    // this.pageForm = new UntypedFormGroup({
    //   title: new UntypedFormControl(this.dashboard.title, [Validators.required]),
    //   description: new UntypedFormControl(this.dashboard.description, [Validators.required]),
    // });
    this.visibletoggle = this.deviceService.isTablet() || this.deviceService.isMobile() ? true : false
    // if (!this.visibletoggle) {
    //   this.options.draggable.enabled = true
    //   this.options?.api?.optionsChanged()
    // }

    this.customizeDashboard()
    if (this.router.snapshot.paramMap.get('dashboardId') || this.data?.dashboard?.dashboardId)
      this.selectedDashboardId = this.router.snapshot.paramMap.get('dashboardId') ?? this.data?.dashboard?.dashboardId
    if (this.selectedDashboardId) {
      this.getDashboard(this.selectedDashboardId)
      this.getDashboardsWidgets(this.selectedDashboardId)
    }


    this.domain = this.dashboard.domain
    this.globalFunctionService.getGlobalFunctions(this.domain).subscribe(result => {
      result.forEach(f => {
        this.scriptLoader.runGlobalFunction(f.code);
      });
    })


  }

  ngOnDestroy(): void {
    if (this.selectedDashboardId)
      deleteCssFile(this.selectedDashboardId)
  }

  openAvailableWidgetScreen() {
    let data = {
      saveselectedWidgets: (widgets) => this.saveselectedWidgets(widgets)
    }
    this.modalService.openModal(data, AvailableWidgetSelectionComponent, '90%', '90%', 'overflow-hidden').subscribe(result => {


    });
  }

  saveselectedWidgets(items: GridsterWidget[]) {
    this.dashboardWidgets = this.dashboardWidgets.concat(items)
    var widgetsLocal = items.map(item => {
      return item.designerContainer.widget
    })
    this.widgets = this.widgets.concat(widgetsLocal)


    this.dashboardWidgets.map(item => {
      this.createWidget(item.designerContainer);

    })
    items.map(async (item) => {
      //Bu servisi çağıracağız
      await this.dashboardService.upsertWidgetToDashboard(item.designerContainer.widget, this.selectedDashboardId).subscribe(result => {

      });
    })
    this.saveDashboard()


  }


  openSaveDashboardModal(event: any) {
    debugger
    if (!this.selectedDashboardId) {
      let data = {
        type: event.type as string,
        widget: this.widgets,
      }
      this.modalService.openModal(data, SelfServiceDashboardFormComponent, 'auto', '50%').subscribe(result => {
        if (result) {
          this.saveDashboard()
        }
      });
    }
    else if (this.selectedDashboardId) {

      let data = {
        widget: this.widgets,
        type: 'update',
        dashboard: this.dashboard
      }

      this.modalService.openModal(data, SelfServiceDashboardFormComponent, 'auto', '50%').subscribe(res => {
        if (res) {
          this.saveDashboard()
        }
      })

    }
    else {
      this.saveDashboard()

    }

  }



  saveDashboard() {
    // this.upsertWidget();
    this.dashboardService.updateDashboardLayout(this.dashboard.dashboardId, this.dashboardWidgets).subscribe(result => {
      if (result) {
        this.coreService.showSuccessMessage(result);
      }
    });
  }


  upsertWidget() {
    // this.widget.freezeFilter = this.thirdForm.value.FreezeFilter == false ? 0 : 1
    // this.dashboardService.upsertWidgetToDashboard(this.widget, this.dashboardId).subscribe(result => {
    //   let dialogData = {
    //     widget: result,
    //     type: this.data.type
    //   }


    // });

    this.dashboard.widgets = this.dashboardWidgets.map(item => item.designerContainer.widget);
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
      if (result) {
        this.coreService.showSuccessMessage(result.message);
      }
    });
  }


  openDashboardDimensions() {
    this.isShownFilter = true;
  }
  closeFilterForm(event: any) {
    this.isShownFilter = false;
  }

  saveFilter(newFilters: BussionFilterDefinition[]) {
    this.closeFilterForm(null);
    this.dashboard.filter = newFilters;
    this.dashboard.widgets = this.dashboardWidgets
    // this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    //   this.getDashboard(this.data.dashboard.dashboardId);
    //   this.dashboardWidgets = [];
    //   this.getDashboardsWidgets(this.data.dashboard.dashboardId);
    // })
  }
  private getDashboardsWidgets(dashboardId: string) {
    this.dashboardWidgets = []
    this.loadingWidgets = true
    this.dashboardService.getWidgetsForDesigner(dashboardId).subscribe(result => {
      this.loadingWidgets = false
      this.widgetList = result

      if (result)
        this.widgets = result.map(item => {
          return item.widget
        });

      result.forEach(widget => {
        this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
        this.createWidget(widget);
      })
    });
  }


  private getDashboard(dashboardId: string) {
    this.dashboardService.getDashboard(dashboardId).subscribe(result => {

      this.dashboard = result;
      this.options.gridType = GridType[this.dashboard.layout]
      // this.options.api.optionsChanged()

      if (this.dashboard.css !== "") {
        // this.cerateCssFile(this.dashboard.css);
      }
      // this.options.minCols = this.dashboard?.gridSize
      // this.options.minRows = this.dashboard?.gridSize
      // this.options?.api?.optionsChanged()
    });
  }

  openShareForm(shareData: any) {
    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.dashboard.dashboardId,
      filter: this.dashboard.filter,
      // function: (filters) => this.saveFilterFromShares(filters)
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {

      }
    })
  }




  widgets: Widget[] = []

  openWidgetForm(event: any) {
    let data = {
      type: event.type,
      widget: event.widget,
      dashboardId: this.dashboard?.dashboardId,
      widgetList: this.widgetList
    }
    this.modalService.openModal(data, WidgetFormComponent, '100%', '90%').subscribe(result => {
      if (result) {

        if (result.type == 'update') {
          let index = this.dashboardWidgets.findIndex(w => w.designerContainer.widgetId == event.widget.widgetId);
          if (index !== -1) {
            // this.dashboardWidgets[index].designerContainer = result.widget;
            this.dashboardWidgets.splice(index, 1);
            this.saveDashboard();

          }
        }
        setTimeout(() => {
          let layout = result.widget.widget.layout;
          this.widgets.push(result.widget.widget)
          this.dashboardWidgets.push({ cols: layout.cols, rows: layout.rows, y: layout.y, x: layout.x, designerContainer: result.widget });

          this.createWidget(result.widget);
          this.saveDashboard();
        }, 1000);

        // this.getDashboardsWidgets(this.selectedDashboardId)
      }
    });
  }
  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  DragModeChange() {
    this.options.draggable.enabled = !this.options.draggable.enabled
    this.options?.api?.optionsChanged()

  }


  private customizeDashboard() {
    this.options = {
      responsiveOptions: [
        {
          breakpoint: 'sm',
          options: {
            maxCols: 1,
            maxRows: 1
          }
        },
        {
          breakpoint: 'md',
          options: {
            maxCols: 2,
            maxRows: 2
          }
        }],
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 24,
      maxCols: 100,
      minRows: 24,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.Always,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
  }

  // getRouteElements() {
  //   return [{ path:"/selfservice", name:"Self Service Home" },{ path:"/selfservice/dashboard-list", name:"Dashboard List" },{ path:"/selfservice", name:"Dashboard Designer" }]
  // }

  // openShareForm(shareData: any) {
  //   let data = {
  //     objectType: shareData.objectType,
  //     objectId: shareData.objectId,
  //     dashboardId: this.dashboard.dashboardId,
  //     filter: this.dashboard.filter,
  //     // function: (filters) => this.saveFilterFromShares(filters)
  //   };

  //   this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
  //     if (result) {

  //     }
  //   })
  // }
  removeWidget(item: any) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.dashboardService.deleteWidgetFromDashboard(this.dashboard.dashboardId, item.designerContainer.widgetId).subscribe(result => {
          this.dashboardWidgets.splice(this.dashboardWidgets.indexOf(item), 1);

          this.widgets = this.widgets.filter(x => x.widgetId !== item.designerContainer.widgetId)
        });
      }
    });
  }
  expandWidget(widgetData) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.dashboard.filter }),
      selectedFilters: this.dashboard.filter,
      selectedDashboard: this.dashboard

    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '600px', '90%', 'overflow-hidden').subscribe(result => { });
  }

  changeGrid(type) {
    this.options.gridType = GridType[type]
    this.options.api.optionsChanged()
    this.dashboard.layout = type
    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
    });
  }


}
