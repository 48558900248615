<div style="display: flex;flex-direction:column">
    <button [matMenuTriggerFor]="viewsListMenu" [class]="buttonClass?buttonClass:'dropdown-button' "
        mat-stroked-button>

        {{ 'MainPage.ViewsButton' | translate }}
        <mat-icon style="height: none;" svgIcon="eye-svgrepo-com"></mat-icon>

    </button>
</div>


<mat-menu #viewsListMenu="matMenu" class="views-menu">
    <div *ngIf="_mySharesForList.length<=0"
        style="width: 100%;display:flex;align-items:center;justify-content: center;">
        <div class="media-heading">{{'ShareComponent.noShares'|translate}}</div>
    </div>
    <app-list-item-with-icon (itemOnclickEvent)="applyFilterForViewsFunction($event)" [buttonsList]="buttonsList"
        *ngFor="let share of _mySharesForList" [item]="share" [class]="'inside-comp'"></app-list-item-with-icon>
</mat-menu>