import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/responses/usersResponse';
import { GroupService } from 'src/app/services/group/group.service';

import { Group } from 'src/app/models/responses/groupsResponse';
import { Role } from 'src/app/models/responses/rolesResponse';
import { RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { DeployAppService } from 'src/app/services/deploy-app/deploy-app.service';
import { App } from 'src/app/models/responses/appsResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';
import { languagePacts } from 'src/app/models/responses/languagePacts';

@Component({
    selector: 'create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

    pageForm: UntypedFormGroup;
    title: string = "";
    passwordAgain: string = "";
    selectedGroups: Group[] = [];
    compareFunctionForGroups = (o1: any, o2: any) => o1.item.groupId === o2.item.groupId;
    selectedRoles: Role[] = [];
    compareFunctionForRoles = (o1: any, o2: any) => o1.roleId === o2.roleId;
    compareFunctionForAccountStatus = (o1: any, o2: any) => o1 === o2.toString();
    groups: Group[] = [];
    roles: Role[] = [];
    user: User = {
        avatar: "",
        userId: "",
        lastLogin: new Date(),
        accountStatus: 1,
        language: "",
        activeDashboards: [],
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        domain: "",
        token: "",
        organization: "",
        homePage: []
    };
    isCreateForm: boolean = true;
    isOwnUser: boolean = false;
    pageVeriable: any = {
        group: { list: [], filtered: [], paginated: [], totalPages: 0, currentPage: 1, search: 'groupName', selected: [], key: 'groupId' },
        role: { list: [], filtered: [], paginated: [], totalPages: 0, currentPage: 1, search: 'roleName', selected: [], key: 'roleId' }
    }

    paginatedGroups: { item: Group, selected: boolean }[] = [];
    pageSize: number = 5
    filteredGroups: { item: Group, selected: boolean }[] = []
    mainpage: string = ''
    appList: App[] = []
    languages: languagePacts[] = []

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CreateUserComponent>,
        private groupService: GroupService,
        private roleService: RoleService,
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private coreService: CoreService,
        private translate: TranslateService,
        private appService: DeployAppService,
        private authService: AuthService,
        private systemService: SystemParametersService
    ) { }

    ngOnInit(): void {
        this.pageForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(this.user.firstName, [Validators.required]),
            lastName: new UntypedFormControl(this.user.lastName, [Validators.required]),
            email: new UntypedFormControl(this.user.email, [Validators.required])
        });

        this.title = this.data.type == 'update' ? this.translate.instant("UserFormPage.TitleUpdate") : this.translate.instant("UserFormPage.TitleCreate");
        this.isCreateForm = this.data.type == 'update' ? false : true;
        this.isOwnUser = this.data.ownUser;
        if (this.data.user) {
            this.user = Object.assign({}, this.data.user);
            this.user.password = "";
            if (this.user.homePage[0])
                this.mainpage = this.user.homePage[0].objectId

        }
        this.getGroups();
        this.getRoles();
        this.getApps();
        this.getLanguages();
    }


    getLanguages() {
        this.systemService.getLanguagePacts().subscribe((res: languagePacts[]) => {
            this.languages = res.filter(x => x.type.toLowerCase().includes('client'))
        })
    }

    getApps() {
        this.appService.getApps().subscribe(res => {
            this.appList = res
        })
    }

    private getRoles() {
        this.roleService.getRoles().subscribe(result => {
            this.roles = result;
            this.pageVeriable['role'].list = this.roles
            this.getUserRoles();
        });
    }

    private getUserRoles() {
        this.roleService.getUsersRoles(this.user.userId).subscribe(result => {
            this.selectedRoles = result;
            this.pageVeriable['role'].selected = this.selectedRoles
            this.pageVeriable['role'].filtered = this.roles.map(item => {
                return { item: item, selected: this.selectedRoles.some(x => x.roleId == item.roleId) }
            })
            this.pageVeriable['role'].totalPages = Math.ceil(this.pageVeriable['role'].filtered.length / this.pageSize);
            this.updatePaginated('role')
        });
    }

    private getGroups() {
        this.groupService.getGroups().subscribe(result => {
            this.groups = result;
            this.pageVeriable['group'].list = this.groups
            this.getUsersGroups();

        });
    }

    private getUsersGroups() {
        this.groupService.getUserGroups(this.user.userId).subscribe(result => {
            this.selectedGroups = result;
            this.pageVeriable['group'].selected = this.selectedGroups
            this.pageVeriable['group'].filtered = this.groups.map(item => {
                return { item: item, selected: this.selectedGroups.some(x => x.groupId == item.groupId) }
            })
            this.pageVeriable['group'].totalPages = Math.ceil(this.pageVeriable['group'].filtered.length / this.pageSize);
            this.updatePaginated('group')
        });

    }

    upsertUser() {
        if (this.pageForm.invalid) {
            this.coreService.showFormFillError();
            return;
        }
        if (this.user.password !== this.passwordAgain) {
            this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.PasswordNotSame"));
            return;
        }

        if (this.mainpage) {
            var date = new Date();

            this.user.homePage = [{
                assignDate: date.toISOString(),
                assignedBy: this.authService.getUser()?.userId,
                objectId: this.mainpage,
                type: 1,
                object: JSON.stringify(this.appList.find(x => x.applicationId == this.mainpage))
            }]
        } else {
            this.user.homePage = []
        }

        this.spinner.show();
        let selectedGroups = this.pageVeriable['group'].selected.map((i: Group) => i.groupId)
        let selectedRoles = this.pageVeriable['role'].selected.map((i: Role) => i.roleId);
        this.user.avatar = this.user.avatar.split(',')[1] == undefined ? this.user.avatar : this.user.avatar.split(',')[1];

        // debugger;

        this.userService.upsertUser(this.user, selectedRoles, selectedGroups).subscribe(result => {
            if (result) {
                this.spinner.hide();
                this.dialogRef.close(true);
            }
        });
        this.changeSystemLanguage(this.user)

    }

    changeSystemLanguage(user: User) {
        var currentUser = this.authService.getUserId()
        if (currentUser == user.userId) {
            this.authService.setLanguage(user.language)
        }
    }

    photoUploaded(photo: string) {
        this.user.avatar = photo;
    }
    currentPage: number = 1
    totalPages: number = 0
    currentPage2: number = 1
    totalPages2: number = 0

    previous(type: string) {
        this.pageVeriable[type].currentPage = this.pageVeriable[type].currentPage - 1
        this.updatePaginated(type)
    }
    next(type: string) {
        this.pageVeriable[type].currentPage = this.pageVeriable[type].currentPage + 1
        this.updatePaginated(type)
    }


    changeselected(event: any, type) {
        var selectedpageveriable = this.pageVeriable[type]

        if (event.options[0]._selected) {
            selectedpageveriable.filtered.find(x => x.item[selectedpageveriable.key] == event.options[0]._value[selectedpageveriable.key]).selected = true
            selectedpageveriable.selected.push(event.options[0]._value)
        } else {
            selectedpageveriable.filtered.find(x => x.item[selectedpageveriable.key] == event.options[0]._value[selectedpageveriable.key]).selected = false
            selectedpageveriable.selected = selectedpageveriable.selected.filter(x => x[selectedpageveriable.key] !== event.options[0]._value[selectedpageveriable.key])
        }

    }

    updatePaginated(type: string) {
        var selectedpageveriable = this.pageVeriable[type]
        const start = (selectedpageveriable.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        var list = selectedpageveriable.filtered
        selectedpageveriable.paginated = list.slice(start, end);
    }

    applyFilter(filterValue: string, type: string) {
        var selectedpageveriable = this.pageVeriable[type]
        var filtered = selectedpageveriable.list.filter((group) => {
            return group[selectedpageveriable.search].toLowerCase().includes(filterValue.toLowerCase())
        });
        selectedpageveriable.filtered = filtered.map(item => {
            return { item, selected: selectedpageveriable.selected.some(x => x[selectedpageveriable.key] == item[selectedpageveriable.key]) }
        })
        selectedpageveriable.totalPages = Math.ceil(selectedpageveriable.filtered.length / this.pageSize);
        selectedpageveriable.currentPage = 1;
        this.updatePaginated(type);
    }


}
