<app-header *ngIf="currentRoute!=='/login'&&showHeader&&currentRoute!=='/main'&&!currentRoute.includes('/selfservice')&&!currentRoute.includes('/main/')&&!currentRoute.includes('data-app')"></app-header>
<router-outlet></router-outlet>
<!-- <button class="chat-button" (click)="openChatbox()">
    <img decoding="async" id="thumbnail" src="../../../assets/images/logoAI.png">
</button> -->
<button *ngIf="permissions.includes('AIUser')&&currentRoute=='/'&&licencePermissions.includes('Bussion AI')" class="chat-button"
    [style.visibility]="isChatboxOpen ? 'hidden' : 'visible'" (click)="toggleChatbox()">
    <img decoding="async" id="thumbnail" src="../../../assets/images/logoAI.png">
</button>
<ngx-spinner></ngx-spinner>
<!-- <button (click)="setParam()">Parametre Kaydet</button> -->