<app-modal-title [title]="title"></app-modal-title>
<mat-dialog-content class="mat-typography">
    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedTabIndex">
        <mat-tab [label]="title">
            <app-title title="{{ 'AppFormPage.AboutApp' | translate }}"></app-title>
            <app-photo-upload type="icon" [photoUrl]="app.icon"
                (photoLoaded)="photoUploaded($event)"></app-photo-upload>

            <div class="b-form-content">
                <div [formGroup]="appForm">

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.AppCode' | translate }}</mat-label>
                        <input matInput [readonly]="selectedApp !== undefined" [(ngModel)]="app.appCode"
                            formControlName="appCode" required>
                    </mat-form-field>
                    <br>

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.Name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="app.name" formControlName="name" required>
                    </mat-form-field>
                    <br>

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.Description' | translate }}</mat-label>
                        <textarea matInput [(ngModel)]="app.description" formControlName="description"></textarea>
                    </mat-form-field>
                    <br>

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.AppStatus' | translate }}</mat-label>
                        <mat-select [(value)]="appStatus">
                            <mat-option value="1">Aktif</mat-option>
                            <mat-option value="0">pasif</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.AppAccess' | translate }}</mat-label>
                        <mat-select [(value)]="appAccess">
                            <mat-option value="1">Public</mat-option>
                            <mat-option value="2">Private</mat-option>
                            <mat-option value="3">Open</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>

                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.OpenerMethod' | translate }}</mat-label>
                        <mat-select [(value)]="appOpenerMethod">
                            <mat-option value="2">New Tab</mat-option>
                            <mat-option value="3">Pop Up</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>

                    <div style="display: flex;align-items: center;justify-content: space-between;gap: 5px;flex: 1;"
                        *ngIf="appOpenerMethod=='3'">
                        <mat-form-field style="flex:0 0 48%">
                            <mat-label>{{ 'WidgetFormPage.WindowType'| translate }}</mat-label>
                            <mat-select [(value)]="appOptions.type"
                                (selectionChange)="changeWindowOptions('type',$event.value)">
                                <mat-option value="centerWindow">Center</mat-option>
                                <mat-option value="leftWindow">Left</mat-option>
                                <mat-option value="rightWindow">Right</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="flex:0 0 20%">
                            <mat-label>{{ 'WidgetFormPage.WindowWidth' | translate }}</mat-label>
                            <input [(value)]="appOptions.windowWidth"
                                (input)="changeWindowOptions('windowWidth',$event.target.value)" type="number" matInput>
                        </mat-form-field>

                        <mat-form-field style="flex:0 0 20%">
                            <mat-label>{{ 'WidgetFormPage.WindowHeight' | translate }}</mat-label>
                            <input [(value)]="appOptions.windowHeight"
                                (input)="changeWindowOptions('windowHeight',$event.target.value)" type="number"
                                matInput>
                        </mat-form-field>

                        <button (click)="resetOptions()" style="flex:0 0 8%" mat-stroked-button
                            class="custom-primary-stroked-button">
                            {{ 'Common.Reset' | translate }}
                        </button>
                    </div>
                    <br>

                    <div style="display: flex;align-items: center;gap: 5px;">
                        <mat-form-field>

                            <mat-label>{{ 'AppFormPage.PrimaryColor' | translate }}</mat-label>
                            <input (focus)="paletColorArea=false" type="color" [formControl]="primaryColorControl"
                                matInput [(ngModel)]="primaryColor" (change)="changeColor($event)" [readonly]="true">
                        </mat-form-field>
                        <button (click)="primaryColor=''" mat-stroked-button class="custom-primary-stroked-button">
                            {{ 'Common.Reset' | translate }}
                        </button>
                        <div>
                            <button style="position: relative;" (click)="paletColorArea=!paletColorArea"
                                mat-stroked-button class="custom-primary-stroked-button">
                                {{ 'AppFormPage.SelectPalet' | translate }}
                            </button>
                            <div class="palet-modal" *ngIf="paletColorArea">
                                <div style="display: flex;justify-content: end;padding-bottom: 5px;">
                                    <mat-icon (click)="paletColorArea=false">clear</mat-icon>
                                </div>
                                <div style="display: flex;flex-wrap: wrap;gap: 5px;justify-content: space-between;">
                                    <div (click)="selectColor(color)" class="color-area"
                                        *ngFor="let color of paletcolors" [style.BackgroundColor]="color" [id]="color">

                                        <mat-icon *ngIf="primaryColor==color"
                                            style="color:#61C478;transform: scale(0.8);">check_circle_outline</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <br>


                    <mat-form-field>
                        <mat-label>{{ 'AppFormPage.AppType' | translate }}</mat-label>
                        <mat-select [(value)]="appType" (focus)="paletColorArea=false">
                            <mat-option value="0">Data App</mat-option>
                            <mat-option value="1">Form App</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>

                    <app-selection-list-with-search (changeselectedList)="changeselectedDahboardlist($event)"
                        [selectedDatas]="selectedDashboards" [keys]="{valueKey: 'dashboardId', titleKey: 'title' }"
                        [title]="'AppFormPage.Dashboards'|translate" [data]="dashboards" [pageSize]="5"
                        [dragable]="true"></app-selection-list-with-search>

                    <app-selection-list-with-search (changeselectedList)="changeselectedGrouplist($event)"
                        [selectedDatas]="selectedGroups" [keys]="{valueKey: 'groupId', titleKey: 'groupName' }"
                        [title]="'UserFormPage.UserGroups' | translate" [data]="groups" [pageSize]="5"
                        [title]=" 'UserFormPage.UserGroups' | translate "></app-selection-list-with-search>


                </div>
            </div>



            <!-- <app-title title="{{ 'UserFormPage.UserGroups' | translate }}"></app-title>
            <mat-selection-list [(ngModel)]="selectedGroups" [compareWith]="compareFunctionForGroups">
                <mat-list-option [selected]="isSelected(group)" *ngFor="let group of groups" [value]="group.groupId">
                    {{group.groupName}}
                </mat-list-option>
            </mat-selection-list>
            <br> -->


        </mat-tab>
        <mat-tab #deneme [label]=" 'AppFormPage.UploadApp' | translate ">
            <diV style="display: flex;justify-content: space-between;">
                <div>
                    *{{ 'AppFormPage.Hint' | translate }}
                    <img style="flex-basis: 50%;" (click)="fileUploader.click()" src="/assets/images/upload.gif" />

                </div>
                <div style="display: flex;flex-direction: column;">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{ 'AppFormPage.AppLink' | translate }}</mat-label>
                        <input matInput [(value)]="app.applicationLink" readonly>
                    </mat-form-field>
                    <mat-label>{{ fileData?.name }}</mat-label>
                    <input hidden #fileUploader (change)="changeFile($event)" type="file" />
                    <button style="margin-left: 10px;margin-top: 5px;" class="b-btn b-success" mat-button
                        color="primary" *ngIf="fileData!==undefined&&app.applicationLink!==''" (click)="uploadFile()">{{
                        'AppFormPage.DeployButton' | translate }}</button>
                </div>

            </diV>
        </mat-tab>

    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="selectedTabIndex!==1">
    <button mat-button class="b-btn" mat-dialog-close>{{ 'Common.Cancel' | translate }}</button>

    <button mat-button [disabled]="!appForm.valid" class="b-btn b-success" *ngIf="!isCreateForm"
        (click)="upsertApp()">{{ 'Common.Update' |
        translate }}</button>
    <button mat-button [disabled]="!appForm.valid" class="b-btn b-success" *ngIf="isCreateForm" (click)="upsertApp()">{{
        'Common.Create' |
        translate }}</button>


</mat-dialog-actions>