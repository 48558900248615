import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import "@angular/compiler";
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomeComponent, Msg } from 'src/app/pages/home/home.component';
@Component({
  selector: 'app-dynamic-home',
  // templateUrl: './dynamic-home.component.html',
  template: '',
  styleUrls: ['./dynamic-home.component.scss'],
  standalone: true
})

export class DynamicHomeComponent implements OnInit {
  @ViewChild(HomeComponent) home: HomeComponent;
  constructor() {
    this.Dashboards = Msg.DataHandler;
    this.selectedPage = Msg.DataHandler;

    this.Applications = Msg.Application;
    this.setCacheddata()
  }
  selectedPage: any[] = []
  selectedList: any[] = []
  Dashboards: Dashboard[] = []
  baseUrl: string = ''
  dashboardService: any;
  scriptLoader: any;
  router: any;
  cssFolder: any;
  page: number = 1
  Applications: any[]
  classNameAll: string = 'filter-active'
  classNameDash: string = ''
  classNameApp: string = ''
  roles: string[] = []
  licencePermissions = localStorage.getItem('licence') ?? ''
  showFirst: HTMLElement
  cacheddata: any
  passVisible: boolean = false

  selectedMenu = 'All'

  ngOnInit(): void {
    this.roles = this.getPermissions()

    this.Dashboards = Msg.Dashboards;
    this.selectedList = Msg.SelectList.filter(function (eleman) {
      return eleman !== undefined;
    });
    this.selectedPage = Msg.DataHandler.filter(function (eleman) {
      return eleman !== undefined;
    });;
    this.baseUrl = Msg.BaseUrl;
    this.dashboardService = Msg.DashboardService;
    this.scriptLoader = Msg.ScriptLoader;
    this.router = Msg.Router;
    this.cssFolder = Msg.CssFolder;
    this.Applications = Msg.Application;
    this.showFirst = document.getElementById("show-first")
    if (this.showFirst && this.showFirst.dataset.value) {
      this.selectedMenu = this.showFirst.dataset.value
      if (this.showFirst.dataset.value == 'App') {
        this.selectedPage = Msg.Application
        this.selectedList = Msg.Application
      } else if (this.showFirst.dataset.value == "Dashboard") {
        this.selectedList = Msg.Dashboards
        this.selectedPage = Msg.Dashboards

      }
    }

    this.setCacheddata()
  }

  getPermissions() {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user'))?.permissions
    else
      return []
  }

  setCacheddata() {
    if (localStorage.getItem('cachedData')) {

      this.cacheddata = JSON.parse(localStorage.getItem('cachedData'))
      Msg.Dashboards = this.cacheddata?.authorizedDashboard?.filter(x => x.isMainDashboard == 1)
      this.Dashboards = Msg.Dashboards
      Msg.Application = this.cacheddata?.authorizedApps
      this.Applications = Msg.Application
    }
  }

  navigation(item: any) {
    var anchor = document.createElement('a');
    // this.setCacheddata()

    if (item.applicationLink) {
      localStorage.setItem('app', JSON.stringify(item))
      let options = {
        type: 'centerWindow', //newWindow,centerWindow,rightWindow,leftWindow
        windowWidth: '100%',
        windowHeight: '100%',
        isapp: 'true',
        app: item
      }

      if (item.options) {
        options = JSON.parse(item.options)
        options.windowHeight = options.windowHeight + '%'
        options.windowWidth = options.windowWidth + '%'

        options['isapp'] = 'true'
        options['app'] = item

      }
      if (item.appType == 0) {
        // let options = {
        //   type: 'centerWindow', //newWindow,centerWindow,rightWindow,leftWindow
        //   windowWidth: '100%',
        //   windowHeight: '100%',
        //   isapp: 'true',
        //   app: item
        // }

        this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
        return
      }

      if (item.openerMethod == 1) {//internal
        window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
      } else if (item.openerMethod == 2) {//newtab
        anchor.href = `${this.baseUrl}/Apps/${item.applicationLink}`;
        anchor.target = "_blank";
        anchor.click();
        // window.open(`${this.baseUrl}/Apps/${item.applicationLink}`, "_blank");
      } else if (item.openerMethod == 3) {//popup
        // let options = {
        //   type: 'leftWindow', //newWindow,centerWindow,rightWindow,leftWindow
        //   windowWidth: '100%',
        //   windowHeight: '100%',
        //   isapp: 'true',
        //   app: item
        // }
        this.scriptLoader.openWindow(`${this.baseUrl}/Apps/${item.applicationLink}`, options)
      }
    } else {
      // this.cookieService.setItem('lastDashboards',)
      this.dashboardService.setLastDashboardId(item.dashboardId)
      this.router.navigate(["/main"], { state: item });
    }
  }

  pagination(pageNumber: number) {
    this.selectedPage = this.selectedList.slice((pageNumber - 1) * 8, ((pageNumber - 1) * 8) + 8)
  }

  openSelfServisMain() {
    this.router.navigate(['selfservice'])
  }



  select(type: string) {
    this.selectedMenu = type
    // this.setCacheddata()
    console.log(this.selectedMenu)
    if (type === 'All') {
      this.selectedList = Msg.Dashboards.concat(Msg.Application)
      this.selectedPage = Msg.Dashboards.concat(Msg.Application)
      this.pagination(this.page = 1)
      this.classNameAll = 'filter-active'
      this.classNameDash = ''
      this.classNameApp = ''
    } else if (type === 'Dashboard') {
      this.selectedList = Msg.Dashboards
      this.selectedPage = Msg.Dashboards
      this.pagination(this.page = 1)
      this.classNameAll = ''
      this.classNameApp = ''
      this.classNameDash = 'filter-active'
    } else if (type === 'App') {
      this.selectedList = Msg.Application
      this.selectedPage = Msg.Application
      this.pagination(this.page = 1)
      this.classNameDash = ''
      this.classNameAll = ''
      this.classNameApp = 'filter-active'
    }

    Msg.DataHandler = this.selectedPage;
    Msg.SelectList = this.selectedList;
  }




}
