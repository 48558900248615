import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatbotComponent } from 'src/app/components/chatbot/chatbot.component';
import { SelfServiceDatastoreFormComponent } from 'src/app/components/self-service-datastore-form/self-service-datastore-form.component';
import { SelfServiceDesignerComponent } from 'src/app/components/self-service-designer/self-service-designer.component';
import { deleteCssFile } from 'src/app/core/utils';
import { BreadCrumElement, LeftMenuProps } from 'src/app/models/globals/customLeftMenuProps';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SelfService } from 'src/app/services/self-service/self.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})
export class SelfServiceComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private dashboardService: DashboardService,
    private dataStoreService: DataStoreService,
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog

  ) {
  }
  roles: string[] = this.authService.getPermissions();
  licencePermissions = localStorage.getItem('licence') ?? ''

  menuList: LeftMenuProps[] = [

    { name: this.translate.instant("SelfServicePage.VisualAnalytics"), icon: 'statIcon', function: () => this.showAnalyzePage(), active: false, path: '/selfservice/analyze', visible: this.roles.some(x => x.includes('SelfService')) },
    // { name: this.translate.instant("SelfServicePage.Flow"), icon: 'flow', function: () => this.showFlow(), active: false, path: '/selfservice/flow', visible: this.roles.some(x => x.includes('DataEngineer')) && this.licencePermissions.includes("Data Flow") },
    { name: this.translate.instant("SelfServicePage.GridAnalytics"), icon: 'grid', function: () => this.gotoGridAnalytics(), active: false, path: '/Apps/gridAnalytics-47681312178742151388-155152/', visible: this.roles.some(x => x.includes('SelfService')) },
    { name: this.translate.instant("SelfServicePage.DashboardList"), icon: 'dashboardIcon', function: () => this.showDashboardTable(), active: false, path: '/selfservice/dashboard-list', visible: this.roles.some(x => x.includes('SelfService')) },
    { name: this.translate.instant("SelfServicePage.MyDatas"), icon: 'dataStore', function: () => this.showDataStoreTable(), active: false, path: '/selfservice/data-store-list', visible: this.roles.some(x => x.includes('SelfService')) },



  ]


  breadcrumbTitle: string = this.translate.instant('HomePage.SelfService')

  breadcrumbElementList: BreadCrumElement[] = [
    { path: "/selfservice", name: this.translate.instant("SelfServicePage.SelfServiceHome") }
  ]

  dashboards: Dashboard[] = [];
  dataStores: DataStore[] = [];
  paginatedDataStores: DataStore[] = [];
  paginatedDashboards: Dashboard[] = [];
  baseUrl = environment.imageUrl;
  currentPage = 0;
  itemsPerPage = 6;
  totalPages = 0;
  userDash: string;

  displayedColumns: string[] = ['icon', 'title', 'description', 'action'];
  displayedColumnsName: string[] = [this.translate.instant("Common.Icon"), 'Title', 'Description', this.translate.instant("DashboardPage.Action")];
  displayedColumnsDataStore: string[] = [this.translate.instant("Common.Icon"), 'name', 'action'];
  displayedColumnsNameDataStore: string[] = ['Avatar', 'Name', this.translate.instant("DataStorePage.Action")];

  showDashboard: boolean = true;
  dataStoreVisible = true;
  showDataStore: boolean = false;

  ngOnInit(): void {


    this.getDashboards();
    this.getDatastores();
    console.log(this.router.url)
    this.getPageTitle(this.router.url)

    this.router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        this.getPageTitle(val.url)
      }
    });
  }

  getPageTitle(url) {
    this.breadcrumbElementList = this.breadcrumbElementList.filter(x => x.path == '/selfservice')
    if (url.includes('self-service-design')) {

      this.breadcrumbElementList.push(
        { path: "/selfservice/dashboard-list", name: this.translate.instant("SelfServicePage.DashboardList") }
      )
      this.breadcrumbTitle = "SelfServicePage.DashboardDesigner"
      return;
    } else if (url.includes('flow-designer')) {
      this.breadcrumbElementList.push(
        { path: "/selfservice/flow", name: this.translate.instant('SelfServicePage.FlowPageTitle') }
      )
      this.breadcrumbTitle = "SelfServicePage.FlowDesigner"
      return;
    }

    switch (url) {
      case "/selfservice/flow":
        this.breadcrumbTitle = 'SelfServicePage.FlowPageTitle'
        break;

      case '/selfservice/dashboard-list':
        this.breadcrumbTitle = "SelfServicePage.DashboardList"
        break;

      case '/selfservice/data-store-list':
        this.breadcrumbTitle = "SelfServicePage.MyDatas"
        break;

      case '/selfservice/analyze':
        this.breadcrumbTitle = 'HomePage.SelfService'
        break;

      case '':

        break;

      default:
        break;
    }
  }




  showDashboardTable() {
    this.dataStoreVisible = false;
    this.router.navigate(['selfservice/dashboard-list']);
  }


  gotoGridAnalytics() {
    var baseUrl = environment.baseUrl
    var link = document.createElement('a');
    // link.href = `/#/selfservice/analyze/grid-analytics/${ds.id}`;
    link.href = baseUrl + `/Apps/gridAnalytics-47681312178742151388-155152/`
    link.target = "_blank";
    var event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    link.dispatchEvent(event);
  }

  showDataStoreTable() {
    this.dataStoreVisible = true;
    this.router.navigate(['selfservice/data-store-list']);
  }

  showFlow() {
    this.router.navigate(['selfservice/flow']);

  }

  showAnalyzePage() {
    this.router.navigate(['selfservice/analyze']);
  }

  toggleMenu(): void {
    const menu = document.querySelector('.menu') as HTMLElement;
    menu.classList.toggle('active');
  }
  openChatbox() {
    this.dialog.open(ChatbotComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'chat-dialog-container',
    });
  }


  getDashboards() {
    this.dashboardService.getAuthorizedDashboards().subscribe(result => {

      // this.dashboards = result;
      this.userDash = localStorage?.getItem('userId');
      const filteredDashboards = result.filter(dashboard => dashboard.ownerId == this.userDash);

      this.dashboards = filteredDashboards
      this.updatePaginationDashboards();
    });
  }

  updatePaginationDashboards() {
    this.totalPages = Math.ceil(this.dashboards.length / this.itemsPerPage);
    this.currentPage = 0;
    this.paginatedDashboards = this.dashboards.slice(
      this.currentPage * this.itemsPerPage,
      (this.currentPage + 1) * this.itemsPerPage
    );
  }

  paginateDashboards(event: any) {
    this.currentPage = event.pageIndex;
    this.paginatedDashboards = this.dashboards.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }
  userDataStore: string;
  getDatastores() {
    // this.dataStoreService.getDataSourceForUser().subscribe(result => {
    //   this.dataStores = result;

    //   console.log(result.filter(x => x.name.includes('post')))
    //   this.userDataStore = localStorage?.getItem('userId');
    //   let filteredDataStore = result.filter(dataStore => dataStore.ownerId === this.userDataStore);

    //   this.dataStores = filteredDataStore;
    //   this.updatePagination();
    // });
  }

  updatePagination() {
    this.totalPages = Math.ceil(this.dataStores.length / this.itemsPerPage);
    this.currentPage = 0;
    this.paginatedDataStores = this.dataStores.slice(
      this.currentPage * this.itemsPerPage,
      (this.currentPage + 1) * this.itemsPerPage
    );
  }

  paginateDataStores(event: any) {
    this.currentPage = event.pageIndex;
    this.paginatedDataStores = this.dataStores.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize
    );
  }


  openDataStoreComponent(event: any) {
    let data = {
      type: event.type as string,
      dataStore: event.data as DataStore
    }
    this.modalService.openModal(data, SelfServiceDatastoreFormComponent, 'auto', '1000px').subscribe(result => {
      if (result === true) {
        this.getDatastores();

        // this.selfService.addDataStore();
      }
    });
  }
  deleteDataStoreComponent(dataStore: DataStore) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dataStoreService.deleteDataStore(dataStore).subscribe(result => {
          this.spinner.hide();
          this.getDatastores();
        });
      }
    });
  }
  open(event: any) {
    this.router.navigate(['landing/dashboard/designer', event.dashboardId]);
  }
  deleteDashboard(dashboard: Dashboard) {
    this.modalService.openDeleteConfirm().subscribe(result => {
      if (result === true) {
        this.spinner.show();
        this.dashboardService.deleteDashboard(dashboard).subscribe(result => {
          this.spinner.hide();
          this.getDashboards();
        })
      }
    });
  }
  openDashboard(event: any) {

    let data = {
      type: event.type as string,
      dashboard: event.data as Dashboard,
    }
    this.modalService.openModal(data, SelfServiceDesignerComponent, '70vh', '90vw', 'fullScreen').subscribe(result => {
      if (result === true) {
        this.getDashboards();

      }
      deleteCssFile(data.dashboard?.dashboardId);
    });
  }

}
