import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { ColorPalette } from 'src/app/models/responses/colorPaletteResponse';
import { ColorPaletteService } from 'src/app/services/color-palette/color-palette.service';
import { environment } from 'src/environments/environment';
import { BussionFilterDefinition, Columns } from 'src/app/models/responses/dataStoreResponse';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CodeEditorComponent } from 'src/app/components/code-editor/code-editor.component';
import { cerateCssFile, deleteCssFile } from 'src/app/core/utils';
import { Router } from '@angular/router';
import { languagePacts } from 'src/app/models/responses/languagePacts';
import { SystemParametersService } from 'src/app/services/system-parameters/system-parameters.service';
import { AuthService } from 'src/app/services/auth/auth.service';

declare let monaco: any;

@Component({
  selector: 'app-dashboard-form',
  templateUrl: './dashboard-form.component.html',
  styleUrls: ['./dashboard-form.component.scss']
})

export class DashboardFormComponent implements OnInit {

  eeditorOptions = { theme: 'vs-dark', language: 'css', automaticLayout: true };

  pageForm: UntypedFormGroup;
  title: string = "";
  compareFunctionForAccessStatus = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForMainDashboard = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForDashStatus = (o1: any, o2: any) => o1 === o2.toString();
  compareFunctionForLanguage = (o1: any, o2: any) => o1 === o2.toString();

  isShownForm: boolean = false;
  color: string = "";
  isCreateForm: boolean = true;
  colorPalettes: ColorPalette[] = [];
  searchValues = [];

  dashboardFilterFields: Columns[] = [];

  queryFilter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: ''
  }
  isShownQueryForm: boolean = false;
  queryFilterFieldType: string = "System.String";


  dashboard: Dashboard = {
    title: "",
    icon: "",
    dashboardStatus: 1,
    order:1,
    description: "",
    layoutConfig: "",
    domain: "",
    access: 2,
    owner: "",
    creationDate: new Date(),
    updatedBy: "",
    updateDate: new Date(),
    colorPalette: "",
    filter: [],
    widgets: [],//Widget,
    dimensions: [], //Dimension,
    messages: [], //BussionMessage
    auditEnabled: 0,
    autoRefresh: 1,
    refreshInterval: 30,
    isMainDashboard: 1,
    hideBorders: 'false',
    gridSize: 12,
    contentLanguage: '',
    tags: "",
    css: "",
    hideAdvancedQueryPanel: 0,
    layout: '',
    colors: []
  }
  customcss: string = '0'

  defaultcss: string = `.widget-header .title { 
    color: #1f1f1f;
} 

.widget-header span { 

visibility: inherit;

} 

.widget-header .sub-title { 

color: #1f1f1f; 
font-style: italic; 

} 
.toolbar{
  background-color: white !important;
}
.widget-container {
   background-color: white;
  }
  
  
`

  tags: string[] = []
  languages: languagePacts[] = []


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardFormComponent>,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private colorService: ColorPaletteService,
    private dataStoreService: DataStoreService,
    private coreService: CoreService,
    private translate: TranslateService,
    private router: Router,
    private systemService: SystemParametersService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.pageForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.dashboard.title, [Validators.required]),
      refreshInterval: new UntypedFormControl(this.dashboard.refreshInterval, [Validators.required, Validators.min(5)]),
      contentLanguage: new UntypedFormControl(this.dashboard.contentLanguage, [Validators.required]),
      description: new UntypedFormControl(this.dashboard.description, []),
    });
    this.tags = []
    let currentTags = this.data?.dashboard?.tags?.split(' ')

    currentTags = currentTags?.filter(x => x !== '')
    for (var i = 0; i < currentTags.length; i++) {
      this.tags.push(currentTags[i])
    }


    this.title = this.data.type == 'update' ? this.translate.instant("DashboardFormPage.TitleUpdate") : this.translate.instant("DashboardFormPage.TitleCreate");
    this.isCreateForm = this.data.type == 'update' ? false : true;
    if (this.data.dashboard) {
      this.dashboard = Object.assign({}, this.data.dashboard);
      this.dashboard.icon = environment.imageUrl + this.dashboard.icon;
      this.setFilterList(this.dashboard.dimensions);
    }

    this.customcss = this.dashboard.css == "" ? '0' : "1"


    // this.getColorPalettes()
    this.getLanguages()
  }

  // getColorPalettes() {
  //   this.colorService.getColorPalettes().subscribe(result => {
  //     this.colorPalettes = result;
  //   });
  // }

  getLanguages() {
    this.systemService.getLanguagePacts().subscribe((res: languagePacts[]) => {
      this.languages = res.filter(x => x.type.toLowerCase().includes('client'))
    })
  }

  upsertDashboard(isSaveAs: boolean) {

    if (this.tags.length == 0) {
      this.dashboard.tags = ""
    }
    var concatstring = ''
    for (var i = 0; i < this.tags.length; i++) {
      concatstring = concatstring.concat(this.tags[i], ' ')
      this.dashboard.tags = concatstring
    }

    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.spinner.show();
    this.dashboard.icon = this.dashboard.icon.split(',')[1];
    this.dashboard.dashboardId = isSaveAs ? "" : this.dashboard.dashboardId;

    if (this.customcss == "0") {
      this.dashboard.css = ""
    } else {
      if (this.dashboard.css.length < 2) {
        this.dashboard.css = this.defaultcss
      }
    }

    this.dashboardService.upsertDashboard(this.dashboard).subscribe(result => {
      if (result) {
        this.spinner.hide();
        this.dialogRef.close(true);
        if (!this.dashboard.dashboardId)
          this.router.navigate(['landing/dashboard/designer', result.object]);
      }

    });
  }

  completeJson(event: any) {

    const input = event.target; // input elementini al

    if (!input.files || input.files.length === 0) {
      console.error("Dosya seçilmedi.");
      return;
    }

    const selectedFile = input.files[0]; // İlk seçilen dosyayı alın

    const reader = new FileReader();
    var jsonData = ''

    reader.onload = (e) => {
      const fileContent: string | ArrayBuffer = e.target.result; // Dosya içeriğini alın
      try {
        jsonData = JSON.parse(fileContent as string); // JSON dosyasını ayrıştırın
        console.log("JSON dosyasının içeriği:", jsonData);
        Object.assign(this.dashboard, jsonData)
        this.dashboard.dashboardId = ''
      } catch (error) {
        console.error("JSON dosyası ayrıştırılamadı:", error);
      }
    };


    reader.readAsText(selectedFile); // Dosyayı metin olarak okuyun
  }

  uploadIconImage(fileInput: any) {
    let file = <File>fileInput.target.files[0];
    if (file.size > 3145728) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.SmallPhoto"));
      return;
    }
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.coreService.showErrorMessage(this.translate.instant("ErrorMessages.WrongFormatPhoto"));
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.dashboard.icon = reader.result;
    }
  }

  addFilter(filter: BussionFilterDefinition) {
    this.dashboard.filter=[...this.dashboard.filter,filter]
    this.isShownQueryForm = false;
    this.queryFilter = {
      fieldName: '',
      fieldValue: '',
      operator: ''
    }
  }
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
    }
    // Clear the input value
    event.input.value = ''
  }

  remove(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags = this.tags.splice(index, 1);
    }
  }

  openBiggerEditor() {
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      height: 'auto',
      width: '90%',
      data: {
        code: this.dashboard.css,
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboard.css = result;
      }
    });
  }

  setFilterList(list: Dimension[]) {
    this.dashboardFilterFields = list.map(d => {
      let f: Columns = {
        fieldName: d.name,
        fieldType: d.type,
        fieldFilter: 1
      }
      return f
    });
  }

  deleteQuery(query: any) {

    let index = this.dashboard.filter.findIndex(x => {
      return x.fieldName === query.fieldName && x.operator === query.operator && x.fieldValue === query.fieldValue;
    });
    if (index !== -1) {
      this.dashboard.filter.splice(index, 1);
    }

  }

  searchValuesForQueryFilter(searchText: string) {
    if (searchText.length < 2) { return };
    /* this.dataStoreService.getValuesForFieldByUniverse(this.dataStore.node, this.dataStore.universe, this.dataStore.collection, this.queryFilter.fieldName, searchText).subscribe(result => {
      this.searchValues = result;
    }) */
  }

  filterFieldSelected(fieldName: string) {
    this.queryFilter.fieldValue = '';
    this.queryFilter.operator = '';
    let dimension = this.dashboard.dimensions.find(c => c.name == fieldName);
    this.searchValues = dimension.value;
    //this.queryFilterFieldType = column.fieldType;
  }

  dateSelected(date: MatDatepickerInputEvent<Date>) {
    this.queryFilter.fieldValue = date.value.toISOString();
  }

  editorInit(eventInit: any) {
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      allowNonTsExtensions: true
    });

    // this.getGlobalFunctionDefinitions();

  }

}