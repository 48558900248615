import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { tap } from 'rxjs/operators';
import { DataStore } from 'src/app/models/responses/dataStoreResponse';
import { CoreService } from 'src/app/services/core/core.service';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss']
})
export class IndexFormComponent implements OnInit {
  selectedcolumnList: string[] = []
  allSelectedColumnList: string[] = []
  dropIndexColumnList: string[] = []
  compareFunctionForSelectList = (o1: any, o2: any) => o1 === o2;
  columns: any[] = [];
  loadingDatastores: boolean = false
  columnsForList = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: { dataStore: DataStore, dataStoreId: string },
    public dialogRef: MatDialogRef<IndexFormComponent>,
    private dataStoreService: DataStoreService, private coreService: CoreService) { }

  ngOnInit(): void {

    if (this.data.dataStore == null) {
      this.getDataStoreById()
    } else {
      this.getColumnInfo()
    }

  }

  getColumnInfo() {
    this.getColumns().toPromise().then(res => {
      this.getIndexedColumns()
    });
  }

  private getDataStoreById() {
    this.loadingDatastores = true
    this.dataStoreService.getDatasourceById(this.data.dataStoreId).subscribe(result => {
      this.loadingDatastores = false
      if (this.data.dataStoreId) {
        this.data.dataStore = result
        this.getColumnInfo()
      }
    });
  }


  private getColumns() {
    return this.dataStoreService.getColumnsByNodeId(this.data.dataStore.nodeId, this.data.dataStore.universe, this.data.dataStore.collection).pipe(
      tap(result => {
        this.columns = result;
        this.columnsForList = this.columns.filter(x => x.fieldName !== undefined)
        this.data.dataStore.columns = this.columns.filter(x => x.fieldName !== undefined)
        console.log(this.data.dataStore.columns, this.columns.filter(x => x.fieldName !== undefined))

      })
    );
  }

  getIndexedColumns() {
    this.selectedcolumnList = []
    this.dataStoreService.getIndexColumns(this.data.dataStore.dataStoreId).subscribe(res => {
      res.map(col => {
        var indexnameprefix = `idx_${this.data.dataStore.collection}_`.replace(/ /g, '')
        var selectedCol = this.data.dataStore.columns.find(x => indexnameprefix + x.fieldName == col)
        if (selectedCol) {
          this.selectedcolumnList.push(selectedCol.fieldName)
        } else {
          var externalcolumn = col

          if (col.includes(indexnameprefix)) {
            externalcolumn = col.split(indexnameprefix)[1]
            this.data.dataStore.columns.push({ fieldFilter: 0, fieldName: externalcolumn, fieldType: '' })
          }
          this.columnsForList.push({ fieldName: externalcolumn, fieldFilter: 0, fieldType: "System.String", visibleName: "" });
          this.selectedcolumnList.push(externalcolumn)

        }
        this.allSelectedColumnList = this.selectedcolumnList

      })
    })
  }

  dropIndexes() {
    return this.dataStoreService.DropIndex(this.data.dataStore.dataStoreId, this.dropIndexColumnList)
      .subscribe(res => {
        if (res.result) {
          this.coreService.showSuccessMessage(res.message)
          this.dialogRef.close(true);
        } else {
          this.coreService.showErrorMessage(res.message)

        }
      })
  }


  createIndexes() {
    let differentElements = this.findDifference(this.selectedcolumnList, this.allSelectedColumnList);
    differentElements.map(item => {
      if (!this.selectedcolumnList.some(x => x == item)) {
        this.dropIndexColumnList.push(item)
      }
    })
    if (this.dropIndexColumnList.length > 0) {
      this.dropIndexes()
    }
    var newaddedList = this.findDifference(this.allSelectedColumnList, this.dropIndexColumnList)


    if (this.findDifference(this.selectedcolumnList, newaddedList).length > 0) {
      this.dataStoreService.createIndex(this.data.dataStore.dataStoreId, this.selectedcolumnList).subscribe(res => {
        if (res.result) {
          this.coreService.showSuccessMessage(res.message)
          this.dialogRef.close(true)
        } else {
          this.coreService.showErrorMessage(res.message)

        }
      })
    }
  }

  selectionChange(event: MatSelectionListChange) {
    this.selectedcolumnList = event.source._value
  }

  iselected(fieldName: string) {
    console.log(this.selectedcolumnList, fieldName)
    return this.selectedcolumnList.some(x => x == fieldName)
  }

  findDifference(arr1, arr2) {
    let difference = arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
    return difference;
  }

}
