<div class="dashboard-page">
    <div class="dashboard-header">
        <div class="dashboard-title">
            <span class="title-text" *ngIf="dashboard">{{dashboard.title}}</span>
        </div>
        <div class="dashboard-button-groups" style="display: flex;gap: 5px;">



            <mat-slide-toggle *ngIf="visibletoggle" [class.custom-active-toggle]="options.draggable.enabled"
                (change)="DragModeChange()" [checked]="this.options?.draggable?.enabled"
                labelPosition="before">{{'DashboardDesignerPage.EditMode'|translate}}</mat-slide-toggle>

            <app-view-button *ngIf="permissions.includes('WidgetShare')||permissions.includes('DashboardShare')" [changeViewList]=" changeViewList"
                [buttonClass]="'custom-primary-stroked-button'" [selectedDashboard]="dashboard"
                (applyFilterForViews)="applyFilterForViews($event)"></app-view-button>



            <button mat-stroked-button class="custom-primary-stroked-button" [matMenuTriggerFor]="gridType"
                style="display: flex;align-items: center;justify-content: center;">
                <mat-icon svgIcon="scale_screen"></mat-icon>
            </button>

            <button mat-stroked-button class="custom-primary-stroked-button" *ngIf="unvisibleWidgets.length>0"
                mat-button [matMenuTriggerFor]="widgetList"
                style="display: flex;align-items: center;justify-content: center;">
                {{ 'DashboardDesignerPage.FilterWidgetLİst' | translate }}
            </button>



            <button mat-stroked-button class="custom-primary-stroked-button" mat-button
                (click)="openDashboardDimensions()">
                {{ 'DashboardDesignerPage.Filters' | translate }}
                <mat-icon svgIcon="filter"></mat-icon>
            </button>

            <button mat-raised-button class="custom-primary-button" (click)="openAvailableWidgetScreen()">
                {{ 'DashboardDesignerPage.AvailableWidget' | translate }}</button>

            <button mat-raised-button class="custom-primary-button"
                (click)="openWidgetForm({widget: null, type: 'create'})">{{
                'DashboardDesignerPage.AddNewWidget' | translate }}</button>

            <button mat-raised-button class="custom-primary-button" (click)="saveDashboard()">{{
                'DashboardDesignerPage.Save' | translate
                }}</button>

        </div>
    </div>

    <div *ngIf="isShownFilter">
        <app-filter-selection [manage]="true" [dimensions]="dashboard.dimensions" [filters]="dashboard.filter"
            (save)="saveFilter($event)" (close)="closeFilterForm($event)" (openShareForm)="openShareForm($event)"
            [hideButtonActive]="this.dashboard.hideAdvancedQueryPanel==0?false:true"
            (saveDimensions)="saveDashboardForDimensionVisibleName($event)" (hideDimension)="hideDimension($event)"
            (saveQueryPanelState)="saveQueryPanelState($event)" [selectedDashboardId]="dashboardId">
        </app-filter-selection>
    </div>
    <div style="height: 90% !important; ">
        <gridster [options]="options">
            <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
                <app-widget-preview [widget]="item" [showDelete]="true" [showEdit]="true" [showZoom]="true"
                    (expandEvent)="expandWidget($event)" (deleteWidget)="removeWidget($event)"
                    (openForm)="openWidgetForm($event)" [selectedDashboard]="dashboard"></app-widget-preview>
            </gridster-item>
        </gridster>
    </div>

</div>

<mat-menu #gridType="matMenu">
    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='Fit'}" (click)="changeGrid('Fit')" mat-menu-item>{{
        'MainPage.FitToScreen' | translate
        }}</span>
    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='Fixed'}" mat-menu-item
        (click)="changeGrid('Fixed')">{{ 'MainPage.FullScreen' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='ScrollVertical'}" mat-menu-item
        (click)="changeGrid('ScrollVertical')">
        {{ 'MainPage.ScrollVertical' |translate }}
    </span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='VerticalFixed'}" mat-menu-item
        (click)="changeGrid('VerticalFixed')">{{ 'MainPage.VerticalFixed' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='ScrollHorizontal'}" mat-menu-item
        (click)="changeGrid('ScrollHorizontal')">{{ 'MainPage.ScrollHorizontal' | translate }}</span>

    <span [ngClass]="{'selectedmenuitem': this.dashboard?.layout=='HorizontalFixed'}" mat-menu-item
        (click)="changeGrid('HorizontalFixed')">{{ 'MainPage.HorizontalFixed' | translate }}</span>



</mat-menu>


<mat-menu class="min-w-40" #widgetList="matMenu">
    <!-- <mat-selection-list [(ngModel)]="unvisibleSelectedList">
        <mat-list-option [value]="item" (click)="$event.stopPropagation()" *ngFor="let item of unvisibleWidgets">
            {{item.designerContainer.widget.title}}
        </mat-list-option>
    </mat-selection-list> -->
    <span *ngFor="let item of unvisibleWidgets" mat-menu-item
        (click)="openWidgetForm({widget: item.designerContainer.widget, type: 'update'})">
        {{item.designerContainer.widget.title}}

    </span>
    <!-- <div (click)="toggleVisibleWidget(unvisibleSelectedList)"
        style="display: flex;align-items: center;justify-content: end;margin-right: 5px;">
        <button mat-stroked-button class="custom-primary-stroked-button">
            {{ 'Common.Apply' | translate }}
        </button>
    </div> -->

</mat-menu>