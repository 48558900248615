import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private headerShown: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }

  getMultiTenantStatus() {
    return this.http.post(Endpoints.getMultiTenantStatus, null).pipe(
      map((response: any) => {
        return response.message;
      })
    );
  }


  showErrorMessage(message: string) {
    this.snackBar.open(message, this.translate.instant('Common.Close'), {
      // duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['mat-toolbar', 'mat-warn']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  showFormFillError(message?: string) {
    this.snackBar.open(message ? message : this.translate.instant("ErrorMessages.FillFields"), null, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  headerStatus() {
    return this.headerShown.asObservable();
  }

  changeHeaderStatus(status: boolean) {
    this.headerShown.next(status);
  }

  loadCustomSVG() {
    this.matIconRegistry.addSvgIcon(
      `add_dashboard`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/add_dashboard.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `clear_all`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/clearAll.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `custom_dashboard`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/custom-dashboard.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `search`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/search.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `file-preview`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/file-preview.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `eye-svgrepo-com`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/eye-svgrepo-com.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `bussion_connector`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/BussionConnector.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `color_palette`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/color_palette.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `comment`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/comment.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `dashboard`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/dashboard.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `data_store`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/data_store.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `deletemessage`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/deletemessage.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `pin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/pin.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `pin-green`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/pin-green.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `filter`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/filter.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `global_function`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/GlobalFunctions.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `script`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/script.svg")
    );


    this.matIconRegistry.addSvgIcon(
      `groups`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/groups.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `main_page`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/main_page.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `menu`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/menu.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `node`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/node.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `roles`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/roles.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `run`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/run.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `scale_screen`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/scale_screen.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `share`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/share.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `users`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/users.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `visual_component`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/visual_component.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `widget_library`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/WidgetLibrary.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `deploy_app`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/app.svg")
    );

    this.matIconRegistry.addSvgIcon(
      `zoom`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/zoom.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `refresh`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/refresh-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `export`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/export-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `download`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/download-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `upload`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/upload-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `restore`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/restore-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `backup`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/backup-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/edit-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `draghand`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/draghand.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sort`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/sort.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sort-green`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/sort-green.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `show-all`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/show-all.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `show-all-green`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/show-all-green.svg")
    );
    this.matIconRegistry.addSvgIcon(

      `message`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/message-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `hide`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/hide-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `main`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/Bmain-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `login`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/login-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `logout`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/logout.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `play`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/play.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `playgray`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/playgray.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `setting-custom`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/setting-custom.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dataStore`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/dataStore-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dashboardIcon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/dashboard-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `robot`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/robot.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `statIcon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/stat.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `setting`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/setting.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `moon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/moon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `flow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/flow.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `scriptIcon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/scriptIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `edit-box`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/edit-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `delete-box`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/delete-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `change-flow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/change-flow.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `zoom-in`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/zoom-in.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `zoom-out`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/zoom-out.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `settings`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/settings.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `error`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `success`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/success.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/arrow-right.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `color-palette`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/color-palette.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `paint`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/paint.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `chart2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/chart2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `custom`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/custom.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/arrow-down.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `multicolor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/multicolor.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `samecolor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/samecolor.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `highttolow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/highttolow.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `lowtohigh`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/lowtohigh.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `measure`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/measure.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sort2`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/sort2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `chat`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/chat.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `variable`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/variable.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `document`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/document.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `activity`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/activity.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `calendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/calendar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `calculate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/calculate.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `Number`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/Number.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `String`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/String.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `DateTime`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/DateTime.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `fx`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/fx.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `file-preview-white`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/file-preview-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `list`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/list.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `stop`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/stop.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/folder.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `person`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/person.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `disableSearch`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/disableSearch.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `filter-outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/filter-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dashboards`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/dashboards.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `server`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/server.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `connection`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/connection.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `open`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/open.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `grid`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/grid.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sortasc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/sortasc.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sortdesc`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/sortdesc.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `hash`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/hash.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `upload-cloud`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/upload-cloud.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `play-tv`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/play-tv.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `stop-square`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/stop-square.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `info`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/info.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dimension`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/dimension.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `chevron-down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/chevron-down.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `chevron-up`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/chevron-up.svg")
    );
    
    
  }


}
